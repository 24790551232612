import { Inject, Injectable, Optional, PLATFORM_ID } from '@angular/core';
import { isPlatformBrowser } from '@angular/common';
import { IBaseServerConfiguration, InitializerService } from 'core-max-lib';

export interface IAppConfigs extends IBaseServerConfiguration {
    contentRoot: string;
    infoRoot: string;
    baseUiRoot: string;
    businessRoot: string;
    businessApiUrl: string;
    comadminBaseUrl: string;
    comadminRedirectUrl: string;
    businessIdNumberLabelText: string;
    fedexUrl: string;
    kranotSpecialId: string;
    kranotConfirmationFlag: boolean;
    maxAccountPaymentHistoryPerPage: number;
    blacklistEncodedCharacters: string[];
    kadamUrl: string;
    maxAccountLeadPageUrl: string;
    enableNewHomepage: boolean;
    enableLoginPopup: boolean;
    isCreditDataForLoans: boolean;
    isCreditDataForOrderCard: boolean;
    googleAPIKey: string;
    maxPhoneNumber: string;
    limitRows: number;
    showMoreAbroadTransaction: boolean;
    showMoreTransaction: boolean;
    showCardActions: boolean;
    FLAG_ISShowTransactionDenySignButton: boolean;
    FLAG_ISEnableNewHomepage: boolean;
    isFairCreditDigitalSignedEnabled: boolean;
    isSpreadDealLabelEnabled: boolean;
    isFairCreditAbroadBillingCycle: boolean;
    leumiCardMerchantTaxId: string[];
    FLAG_ISTransactionDenyShowContactUs: boolean;
    numOfTransactionsToSpreadToDisplay: number;
    numOfSpreadingAutorizationAllowed: number;
    minAmountOfSpreadingAutorizationAllowed: number;
    minAmountOfTransactionsToSpreadToDisplay: number;
    skyMaxUrl: string;
    skyMaxCardId: string;
    skyMaxCardIdBusiness: string;
    allClubCardId: number;
    lorealCardId: number;
    iNeedItCardId: number;
    cryptoSuperKCardId: number; // maxBack Crypto
    FLAG_ISOrderCardInsurance: boolean;
    serverToClientTransitionTimeout: number;
    FLAG_ISNeedToOfferNabat: boolean;
    FLAG_ISMailOtpEnabled: boolean;
    FLAG_ISAgreementsShowNumDays: boolean;
    FLAG_ISShowCardFeesForTransactionPopup: boolean;
    JoinFormDocsAttachmentText: string;
    FLAG_ISRedirectToCustomerLoan: boolean;
    FLAG_ISFingerPrintEnabled: boolean;
    FLAG_ISFingerPrintEnabledWithoutQp: boolean;
    FLAG_ISPreventLoanEnabled: boolean;
    FLAG_ISScavPageEnabled: boolean;
    FLAG_ISRedirectToFixChargeOnlineLC: boolean;
    FLAG_ISShowSignLoanButton: boolean;
    FLAG_ISGetSignLoanUrlMock: boolean;
    FLAG_ISShowInterestInLoanSummary: boolean;
    secondsTimeForWatchCardDetailsInInstantIssuing: number;
    secondsTimeForWatchVerificationCodeInInstantIssuing: number;
    FLAG_ISSkyMaxBusinessEnabled: boolean;
    FLAG_ISUsingNewAuthRecoverPageOnlineLC: boolean;
    routeDelayTimeInMilliseconds: number;
    FLAG_ISCanOrderDigitalCard: boolean;
    FLAG_ISRedirectToNewLogin: boolean;
    FLAG_ISSkipSigningInstantIssuing: boolean;
    daysUntilIdWillBeExpired: number;
    JoinFormDocsAttachmentTextForBiometricID: string;
    FLAG_ISProfileLoanRemoveAccountType: boolean;
    FLAG_ISNewTextLoanSuccess: boolean;
    signInWithAppleEnabled: number;
    FLAG_ISNeedToDisableAddress: boolean;
    FLAG_ISEnableEarlyRepayment: boolean;
    FLAG_ISEnableScavTimeoutApi: boolean;
    FLAG_ISSpreadTransactionCreditBridgingInterest: boolean;
    spreadTransactionCreditMCCList: string[];
    returnUrlDomainList: string[];
    FLAG_ISEnableInsuranceDetails: boolean;
    FLAG_ISShowInsuranceRenewButton: boolean;
    FLAG_ISEnableVGCBankTransfer: boolean;
    FLAG_ISTransferHokDefaultYesAndFillMyCardNumber: boolean;
    FLAG_ISRedirectToNewSpreadTransaction: boolean;
    FLAG_ISMainHeaderScriptFirstLoad: boolean; // FLAG_ISLoadTrusteerSnippetScript
    FLAG_ISUseDeeplinkAndMobileSsoForOpenBanking: boolean;
    FLAG_ISHokMultipleCard: boolean;
    FLAG_ISEnableAigSso: boolean;
    FLAG_ISOpenDropdownInDirectDebit: boolean;
    FLAG_ISUserById: boolean;
    FLAG_ISEnableCreditMulti: boolean;
    FLAG_ISHomepageExcoEnabled: boolean;
    FLAG_ISSkyMaxRafBannerEnabled: boolean;
    FLAG_ISEnableBiometricPopup: boolean;
    FLAG_ISEnabledTagging: boolean;
    FLAG_ISUseUmbracoPic: boolean;
    FLAG_ISUseRecommendationNewDesignAndLottie: boolean;
    FLAG_ISPushSubscription: boolean;
    FLAG_ISDontShowJoinAgreements: boolean;
    numFutureMonthsTransactions: number;
    SavingAnnualFeeManagement: number;
    SavingGrossAnnnualReturn: number;
    FLAG_ISFutureMonthsTransactions: boolean;
    FLAG_ISWeezmoByDatesOnWebEnabled: boolean;
    FLAG_ISEnableHistoryVGC: boolean;
    CustomerLoan60PaymentsMaxSum: number;
    CustomerLoan72PaymentsMaxSum: number;
    FLAG_ISWebViewEndButton: boolean;
    FLAG_ISScanovateInWebViewEnabled: boolean;
    FLAG_ISTakeMinOtbSaving: boolean;
    FLAG_ISEnableGetGiftCardTransaction: boolean;
    FLAG_ISNewCreditData: boolean;
    FLAG_ISEnableDisableCards: boolean;
    FLAG_ISEnableCreditDataAgreeFirstDate: boolean;
    SavingMatElib: boolean[][];
    SavingRangeElib: {
        key: number;
        value: number[];
    }[];
    FLAG_ISDisplayDashboardInsurance: boolean;
    FLAG_ISSavingEnableScav: boolean;
    FLAG_ISSavingFileUploadEnabled: boolean;
    CreditLimitMinimumSum: number;
    CreditLimitMaximumSum: number;
    BumpYardChatScript: string;
    BumpYardChatBaseDomain: string;
    BumpYardChatStreamId: string;
    FLAG_ISCheckTehilaUserByTzEnabled: boolean;
    FLAG_ISShowAdditionalCreditIncreaseLink: boolean;
    MAX_ACCOUNT_CONFIGS_DASHBOARD_PENDING_MAX_ROWS: number;
    MAX_ACCOUNT_CONFIGS_DASHBOARD_MERCHANT_NAME_CHARGES: string; // חיוב כרטיס אשראי
    MAX_ACCOUNT_CONFIGS_DASHBOARD_MERCHANT_NAME_TRANSFER_TO_BANK: string; // משיכה לחשבון בנק
    MAX_ACCOUNT_CONFIGS_DASHBOARD_MERCHANT_NAME_NET_INCOME: string; // הכנסות נטו
    FLAG_ISUseUmbracoSearch: boolean;
    FLAG_ISShowMaxAccountInDashboard: boolean;
    Flag_ISEnableForeignCurrencyPocketsInHomePage: boolean;
    FLAG_ISShowFedexButtonJoinCrypto: boolean;
    FLAG_ISMaxAccountAuthorization: boolean;
    FLAG_ISEthocaEnabled: boolean;
    FLAG_ISPassKnowBetterSideSaving: boolean;
    FLAG_ISGotoDigitalCardAfterBlocking: boolean;
    MAX_ACCOUNT_CONFIGS_DASHBOARD_MERCHANT_NAME_NOT_CLICKABLE: string;
    FLAG_ISShowMaxAccountOpentoBuyDaily: boolean;
    CarLoansUploadFileTZCode: string;
    CarLoansUploadFileTZCodeBack: string;
    CarLoansUploadRelatedDocuments: string;
    FLAG_ISShowMaxAccountDashboardWaitingWithdraws: boolean;
    FLAG_ISShowMaxAccountChoosePayment: boolean;
    FLAG_ISIgnoreCopyFailedMaxAccountChoosePayment: boolean;
    MAX_ACCOUNT_CONFIGS_MAX_PAYMENTS: number;
    FLAG_ISShowSignVGCOrderButton: boolean;
    FLAG_ISShowCreditDataSignaturePadForJoinMatal: boolean;
    FLAG_ISUseUserIdTypeInRecover: boolean;
    FLAG_ISEnableUnfreezeAddToWhitelist: boolean;
    FLAG_ISDisplayCreditDataAgreementMoreDetails: boolean;
    MAX_HOURS_SINCE_AGREE_START_DATE: number;
    MAX_ACCOUNT_CONFIGS_FUTURE_INCOME_PAGE_SIZE: number;
    FCP_OPEN_ACCOUNT_TIMER_DURATION: number;
    FCP_OPEN_ACCOUNT_MIN_AMOUNT: number;
    MAX_ACCOUNT_CONFIGS_DASHBOARD_MERCHANT_NAME_EXTRA_CHARGE: string; // עסקה מחוץ למסגרת הכרטיס
    FLAG_ISEnableNewRegistrationScreen: boolean;
    FLAG_ISEnableNewRenewPasswordScreen: boolean;
    FCP_REFILL_MIN_AMOUNT: number;
    FCP_SELL_MIN_AMOUNT: number;
    FLAG_ISEnableBiometricCheckBoxInNewRegistration: boolean;
    NewSavingsLoadingScreenTimeInMiliSeconds: number;
    SavingsInvestmentDefaultValueForOneTimeExternal: number;
    SavingsInvestmentDefaultValue: number;
    FLAG_ISEnableNavigateBusinessOpenBanking: boolean;
    FLAG_ISEnableDashboardMyCardsRecommendationsFromPersonalMessages: boolean;
    FLAG_ISNewDeliveryForBlockCard: boolean;
    FLAG_ISBeeriEmergency: boolean;
    FCP_REFILL_TIMER_DURATION: number;
    FCP_SELL_TIMER_DURATION: number;
    FLAG_SavingCheckEligibilityV2: boolean;
    DisableRecaptchaInLeadPageListOfProducts: string;
    FLAG_IsRedirectToTotalMaxBack: boolean;
    TotalMaxBackMarketingPageUrl: string;
    FLAG_ISOrderCardSessionToken: boolean;
    convertToTotalCardDelayTimeInMilliseconds: number;
    FLAG_ISFcpTransactionsNew: boolean;
    DifferenceLimitInMinutes_CardActivation_OtpLoginTime_PinCodeRequest: number | null;
    DifferenceLimitInMinutes_CardActivationTime_PinCodeRequest: number;
    FLAG_ISEnableSet3DS: boolean;
    FLAG_SpreadTransactionNewSuccessPage: boolean;
    CitizenshipQuestionDate: string;
    IssuerIdsNoDisplayDigitalBackCardNote: string;
    FLAG_ISAROFM178NewFieldAnotherCitizenship: boolean;
    SavingDateStartNewField: string;
    FLAG_ISNavigateFromHomepageToMySavingWeb: boolean;
    FLAG_IsNewSkyMaxChart: boolean;
    FLAG_ISRedirectToNewCancelDirectDebitPage: boolean;
    MAX_FUTURE_BENEFITS_AMOUNT: number;
    MinForPromotionForCharging: number;
    FLAG_ISRedirectToNewBenefitOrder: boolean;
    CouponTmuratPinukMaximum: number;
    FLAG_EnableSplitIrgunHamurim: boolean;
    FLAG_IsSpreadTransactionByCampain: boolean;
    FLAG_ISEnableMyMAXChildActionsWithoutALink: boolean;
    FLAG_MarkMyMaxCardOTBIsLowForParent: boolean;
    FLAG_ISNewScanovate: boolean;
    FLAG_ISCreditDataAgentMaxDate: boolean;
    GiftcardChargingToAppUrl: string;
    GiftcardChargingToBalanceInquiryUrl: string;
    GlobalMaslulAnnualCostPercentage: number;
    StockMaslulAnnualCostPercentage: number;
    ObligationMaslulAnnualCostPercentage: number;
    IsraelObligationMaslulAnnualCostPercentage: number;
    FLAG_ShowSpreadTransactionsBanner: boolean;
    OfferOtherTransactions: number;
    FLAG_ISScanovateInRegistrationAndRecover: boolean;
    FLAG_ISNewCRMScanovate: boolean;
    creditLawSigningAddMonth: number;
    FLAG_ISShowInMenuGoToBusinessUrl: boolean;
    FLAG_ISFixChargeNewAsterix: boolean;
    FLAG_RedirectManagmentLoyaltyToChangeCourse: boolean;
    FLAG_SplittingWhatsappAgreements: boolean;
    MokedMoadonimPhone: string;
    FLAG_IsUseViewCardDetailsInd: boolean;
    FLAG_ISCancelShowOptionOnTimeFixCharge: boolean;
    FLAG_deceasedrelativeInfoType: boolean;
    FLAG_BypassSpreadTransactionsBannerCycleCondition: boolean;
    MaximumFilesToUpload: Number;
    ReceiptsStopDate: string;
    FLAG_PopUpMessageStopReceipts: boolean;
    FLAG_StopWeezmoAndEthoca: boolean;
}

@Injectable()
export class AppConfigsService {
    appConfigs: IAppConfigs;
    private isBrowser: boolean;
    private originUrl;

    constructor(
        @Optional()
        @Inject('serverUrl')
        private serverUrl: string,
        @Inject(PLATFORM_ID) private platformId: any,
        private initializer: InitializerService
    ) {
        this.isBrowser = isPlatformBrowser(platformId);
        this.originUrl = this.isBrowser ? window.location.origin : this.serverUrl;
        this.appConfigs = this.initializer.getAppConfigs();
        if (this.originUrl.includes('cardholderslc-comadmin8')) {
            this.appConfigs.apiUrl = this.appConfigs.apiUrl.replace('onlinelcapi', 'cardholderslcapi-comadmin8');
        }
        if (this.originUrl.includes('cardholderslc-comadmin.max-stg')) {
            this.appConfigs.apiUrl = this.appConfigs.apiUrl.replace('www.max-stg', 'cardholderslc-comadmin.max-stg');
        }
        if (this.originUrl.includes('www30')) {
            this.appConfigs.apiUrl = this.appConfigs.apiUrl.replace('www', 'www30');
            this.appConfigs.contentRoot = this.appConfigs.contentRoot.replace('www', 'www30');
            this.appConfigs.baseUiRoot = this.appConfigs.baseUiRoot.replace('www', 'www30');
        }

        // this.appConfigs.contentRoot = this.appConfigs.contentRoot.replace('domain', this.appConfigs.domain);
        // this.appConfigs.infoRoot = this.appConfigs.infoRoot.replace('domain', this.appConfigs.domain);
        // this.appConfigs.baseUiRoot = this.appConfigs.baseUiRoot.replace('domain', this.appConfigs.domain);
        // this.appConfigs.businessRoot = this.appConfigs.businessRoot.replace('domain', this.appConfigs.domain);
        // this.appConfigs.businessApiUrl = this.appConfigs.businessApiUrl.replace('domain', this.appConfigs.domain);
        // this.appConfigs.comadminBaseUrl = this.appConfigs.comadminBaseUrl.replace('domain', this.appConfigs.domain);
        // this.appConfigs.kadamUrl = this.appConfigs.kadamUrl.replace('domain', this.appConfigs.domain);
        this.appConfigs.companyName = 'max';
        this.initEvent();
    }
    initEvent() {
        if (this.isBrowser) {
            document.addEventListener('getFlags', e => {
                document.dispatchEvent(
                    new CustomEvent('receivesFlags', {
                        detail: {
                            returnCode: 0,
                            flags: this.appConfigs
                        }
                    })
                );
            });
            document.addEventListener('updateFlag', (e: any) => {
                this.appConfigs[e.detail.key] = e.detail.value;
                document.dispatchEvent(
                    new CustomEvent('flagUpdated', {
                        detail: {
                            returnCode: 0
                        }
                    })
                );
            });
        }
    }
}
