import { Subject } from 'rxjs';
import { isNullOrUndefined } from 'core-max-lib';
import { FormService } from './../components/form-components/dynamic-form-services/form.service';
import { Injectable, Inject, PLATFORM_ID } from '@angular/core';
import { Observable } from 'rxjs';
import { HttpService, IResponse, IResponse as IResponse1, IResponseLower } from '../cardholders-core/services/http-service.service';
import { HttpHeaders, HttpParams } from '@angular/common/http';

import { RestApiService } from './rest-api.service';
import { CustomerType } from '../enums/customer-type.enum';
import { ComponentBase } from '../components/form-components/dynamic-form-control/controls/control-base';

import { IFile } from '../models/file';
import { FormGroup } from '@angular/forms';
import { METADATA } from '../../modules/join-form/metadata';
import { METADATA_ELIGIBILITY } from '../../modules/join-form/metadata-eligibility';
import { IJoinRequest } from '../models/join-request';
import { AppConfigsService } from '../../config/app-configs.service';
import { isPlatformBrowser } from '@angular/common';
import { ActivatedRoute, Router } from '@angular/router';
import { TokenService } from '../cardholders-core/services/token.service';

import { ICard } from '../models/cards/card';
import { IUpdateRequest, ActionCode, IIframeUrlResult, ICreditLimitSplitDetails } from '../models/update-request/update-request';
import { IWorkplace } from '../models/workplaces/workplace';
import { IVerifyOtp, ICustomerData, ICustomerData as ICustomerData1 } from '../models/verify-otp/verify-otp';
import { IFedexToken } from '../models/fedex-token/fedex-token';
import { ISendOtp } from '../models/send-otp/send-otp';
import { IBanks } from '../../modules/join-form/models/banks/banks';
import { IStreets } from '../../modules/join-form/models/streets/streets';
import { ICities } from '../../modules/join-form/models/cities/cities';
import { ISalesPromotionAgent } from '../models/sales-promotion-agent';
import { IContinueExternalRequest } from '../models/continue-external-request';
import { GtmService } from '../cardholders-core/services/gtm.service';
import { ICardLeadDetails } from '../../modules/cards/components/card-page/models/card-lead-details';
import { IA2CInstitutesByProductIdResult, IA2CPopulationsAndTZIndResult, IA2CPictureNeedIndResult, IGetInstitutesDetails, IGetPopulationsAndTZIndDetails, IGetPictureIndDetails } from '../models/get-institutes-details';
import Compressor from 'compressorjs';
import { SpinnerService } from '../cardholders-core/services/spinner.service';
import { JoinHelperService } from "../cardholders-core/services/join-helper.service";
import { tap } from 'rxjs/operators';

interface SelectItem {
    label: string;
    value: string;
}

@Injectable()
export class JoinService {
    cardProductPages: {cardPages: {id: string, url: string}[]} = null;
    card: ICard;

    joinRequest: IJoinRequest;
    salesPromotionAgent: ISalesPromotionAgent;
    file: IFile;
    backSideFile: IFile = {
        attachedFile: null,
        attachmentCode: "",
        departmentCode: ""
    };
    idDateOfExpiration?: string = null;
    idDateOfIssue?: string = null;

    customerType: CustomerType = CustomerType.new;
    AsBsIndication: number;
    passedEligibility = false;
    otpValid = false;
    isApproveToExit = new Subject();
    canExit: boolean;
    isComplete = new Subject<boolean>();
    SttDigitalLtrRegInd?: number;
    SttBillCode?: number;
    digitalLettersCode?: number;
    isNewRequest = true;
    isTrackerRecoverRequest = false;
    foxNeedToPayMembershipInd?: number;
    isDreamCardSmsSuccess = true;
    CreditLimitInd: boolean;
    CreditLimitTemp?: number;
    IsBasicClub: boolean;
    KranotPopCode?: number;
    proposalLogoCodeDesc: string;
    customerExists = false;
    restoredOpenRequest = false;
    isExternalRequest: boolean= false;
    iframeUrlResult: IIframeUrlResult;
    isIframeNeedToBeSigned: boolean;
    MinimumFix?: number;
    FixPayment?: number;
    RealInterest?: number;
    EffectiveInterest?: number;
    FixedChargeAllowedInd?: boolean;
    OpenRequestDetails = {
        CreateDate: '',
        CardId: '',
        CardName: '',
        CardDetails: null
    };
    isOpenSkyMaxPopUp :boolean = false;
    hideBonusSection :boolean = false;
    isOpenRequestCardIdDifferentThenCurrent= false;
    creditLimitSplitProposalResult: ICreditLimitSplitDetails = {
        CardImageUrl: "",
        CardTitle: "",
        CreditLimitOnExistingCard: 0,
        MaxCreditLimitForRequest: 0,
        MinCreditLimitForRequest: 0,
        ShortCardNumber: ""
    };
    isFromGetRequest: boolean = false;
    needToDisableAddress: boolean = false;

    stepsMap = [
        { step: 1, path: 'personal-details' },
        { step: 2, path: 'address' },
        { step: 3, path: 'occupation-details' },
        { step: 4, path: 'card-details' },
        { step: 5, path: 'confirmation' },
        { step: 6, path: 'success' }
    ];

    stepKey: string;
    stepsLoaded = new Subject();
    steps: {
        id: number;
        header: string;
        key: string;
        view: ComponentBase<any>[];
        formGroup: FormGroup;
    }[] = [];
    stepIndex: number;
    stepsReady = false;

    CreditLimit: number;

    interest: number;

    loyaltyCode: number;

    cardTypeHebrew: string;

    isDreamCard = false;

    isKranot = false;

    isSpecialKranot = false;

    isTeacher = false;

    isTau = false;

    isUniq = false;

    isCryptoSuperK = false;

    isVictoryCard = false;

    is1824ClubCard = false;

    isTotalMaxBackCard = false;

    isIsicCard = false;

    isClalCard = false;

    needDocInd = false;

    stepsReadySubject: Subject<object> = new Subject();

    stopJoinFormClicked: Subject<void> = new Subject<void>();

    isWinCard = false;

    isSyncedValues = false;

    private isBrowser: boolean;
    isExistCustomerAlreadySyncedData = false;
    isPassedOtp: boolean = false;
    isPassedMaxAccountOtp: boolean = false;
    needToCleanToken: boolean = true;

    tauUniqPictureInd = false;

    creditDataWasShownOnStep3: boolean = false;
    transportationTypeCode: string;
    isMaxAccountRequest: boolean = false;
    isEligibilityRequest: boolean = false;

    constructor(
        private httpSvc: HttpService,
        private frmSvc: FormService,
        private restApiService: RestApiService,
        private appConfigsService: AppConfigsService,
        @Inject(PLATFORM_ID) private platformId: any,
        private activatedRoute: ActivatedRoute,
        private tokenService: TokenService,
        private gtmService: GtmService,
        private spinnerService: SpinnerService,
        private joinHelperService: JoinHelperService,
        public router: Router
    ) {
        this.isBrowser = isPlatformBrowser(this.platformId);
        this.joinRequest = {
            RequestId: null,
            IdNumber: null,
            FirstName: null,
            LastName: null,
            BirthDate: null,
            Gender: null,
            CellularPhoneNumber: null,
            HomePhoneNumber: null,
            Email: null,
            HomeCity: null,
            HomeStreet: null,
            HomeHouseNum: null,
            HomeEntrance: null,
            HomeApartment: null,
            ZipCode: null,
            MailCity: null,
            MailStreet: null,
            MailHouseNum: null,
            MailEntrance: null,
            MailApartment: null,
            MailPob: null,
            MutagCode: null,
            BillCycleCode: null,
            BankId: null,
            BranchId: null,
            AccountType: null,
            AccountNumber: null,
            AccountSeniority: null,
            AccountOwnerName: null,
            AccountOtherClientInd: null,
            AccountOtherClientFirstName: null,
            AccountOtherClientLastName: null,
            AccountOtherClientIdNumber: null,
            CardInd: null,
            WorkStatusCode: null,
            FinanceOriginCode: null,
            MyNetIncomeCode: null,
            EducationCode: null,
            CompanyName: null,
            MyCardDeclaredLogoCode: null,
            MyCardNumber: null,
            MyCardExpMonth: null,
            MyCardExpYear: null,
            MaidenName: null,
            GrandmotherName: null,
            PublicDutyRelatedInd: null,
            PublicDutyRelatedFirstName: null,
            PublicDutyRelatedLastName: null,
            PublicDutyDescription: null,
            IndustryAndSecurityInd: null,
            OilAndGazInd: null,
            EnergyNaturalResourcesInd: null,
            RealEstateConstructionInd: null,
            ServToGovermentPublicInd: null,
            AssociasionAndSportInd: null,
            AnotherCitizenshipInd: null,
            Bank411RefusalServicesInd: null,
            FirstNameEng: null,
            LastNameEng: null,
            ForUseByCode: null,
            AveragePurchaseCode: null,
            FixedChargeInd: null,
            FixedChargeAmount: null,
            FixedChargeReasonCode: null,
            ShowPreviousChargesInd: null,
            BenefitPlanDescription: null,
            StandingOrderInd: null,
            StandingOrderCardNumber1: null,
            StandingOrderCardNumber2: null,
            StandingOrderCardNumber3: null,
            IvrServiceCode: null,
            DigitalLettersInd: null,
            DigitalLettersCode: null,
            MemberTypeCode: null,
            MemberIdNumber: null,
            MilitaryNumber: null,
            CertificateNumber: null,
            ApplicationProduct: null,
            TransferDataToFoxAllowInd: null,
            MarriageDate: null,
            ArrayOfFoxChildDetails: null,
            ClubCode: null,
            FriendJoinCode: null,
            KranotTypeCode: null,
            TeacherTypeCode: null,
            IdNumberTeacher: null,
            IdNumberKranot: null,
            AdditionalIdNumber: null,
            UniInstituteCode: null,
            BankCreditLimit: null,
            MemberNum: null,
            ProductName: null,
            WorkplaceClubCode: null,
            IdNumberAgent: null,
            InternetCardInfo: null,
            CreditDataAgreeInd: null,
            CreditDataIdentCode: null,
            CreditDataAgreeExpDate: null,
            CreditDataIdenCustomerInd: null,
            CreditDataSignaturePad: null,
            AllowOfferLoanByRepInd: null,
            TransDataToHashukAllowInd: null,
            InsuranceInd: null,
            SourceCode: null,
            ApplicationWayCode: null,
            DivurYashirSpamInd: null,
            CreditLimitForTheRequest: null,
            TransDataToVictoryAllowInd: null,
            IsicInstituteCode: null,
            AgreeToPayMembershipIsicInd: null,
            TransDataTo1824AllowInd: null,
            TransDataToClalAllowInd: null,
            TransDataThirdPartyAlwIndToClal: null
    };

        this.file = {
            attachedFile: null,
            departmentCode: null,
            attachmentCode: null
        };

        this.isApproveToExit.subscribe(data => {
            this.canExit = data ? true : false;
        });
        this.isMaxAccountRequest = this.joinHelperService.isMaxAccountRequest;

        const sub = this.getCardProductPages().subscribe(_ => sub.unsubscribe());
    }

    // One time view loading
    createAllViews() {
        return new Observable(observer => {
            if (this.stepsReady && !this.passedEligibility) {
                observer.next();
            } else {
                this.getMetadata().subscribe(
                    metadata => {
                        this.steps = [];
                        this.frmSvc.flatComponentTree = {};
                        this.frmSvc.flatFormControls = {};
                        if (metadata) {
                            metadata.forEach((element, index) => {
                                const step: {
                                    view: ComponentBase<any>[];
                                    formGroup: FormGroup;
                                } = this.frmSvc.createView(element.key, element);
                                this.steps = [
                                    ...this.steps,
                                    {
                                        id: index + 1,
                                        header: element.header,
                                        key: element.key,
                                        view: step.view,
                                        formGroup: step.formGroup
                                    }
                                ];
                            });
                            this.stepsReady = true;
                            this.stepsReadySubject.next();
                            observer.next();
                        } else {
                            observer.error('matadata not found');
                        }
                    },

                    error => {
                        console.log(error);
                    }
                );
            }
        });
    }

    scrollTopOnLoad(elmName) {
        if (this.isBrowser) {
            const elm = document.getElementsByClassName(elmName);
            elm[0].scrollTop = 0;
        }
    }

    setFileData(fileData: any) {
        this.file.attachmentCode = this.card.ArchiveDocTypeCode;
        this.file.departmentCode = this.card.ArchiveDeptNumber;
        this.compressImage(fileData).then((file) => {
            this.file.attachedFile = file;
        });
    }

    compressImage(file: File): Promise<File> {
        return new Promise<File>((resolve, reject) => {
            new Compressor(file,
                {
                    quality: 0.5,
                    maxWidth: 1000,
                    maxHeight: 1000,
                    success: (result) => {
                        resolve(new File([result], file.name, { type: result.type }));
                    },
                    error: (error: Error) => reject(error)
                });
        });
    }

    getCard(cardId: number): Observable<ICard> {
        return new Observable<ICard>(observer => {
            this.restApiService.getCard(cardId.toString()).subscribe((card: ICard) => {
                if (isNullOrUndefined(card)) {
                    return;
                }
                this.card = card;
                this.setLongSpinnerInfo();
                this.isDreamCard = this.card.ProductCode === '39';
                this.isKranot = this.card.ProductCode === '40';
                this.isWinCard = this.card.ProductCode === '42';
                this.isTeacher = this.card.ProductCode === '32';
                this.isTau = this.card.ProductCode === '44';
                this.isUniq = this.card.ProductCode === '45';
                this.isCryptoSuperK = this.card.ProductCode === '46';
                this.isVictoryCard = this.card.ProductCode === '48';
                this.isTotalMaxBackCard = this.card.ProductCode === '50';
                this.isIsicCard = this.card.ProductCode === '49';
                this.is1824ClubCard = this.card.ProductCode === '52';
                this.isClalCard = this.card.ProductCode === '54';
                observer.next(this.card);
                observer.complete();
            });
            this.isSpecialKranot = cardId.toString() === this.appConfigsService.appConfigs.kranotSpecialId.toString();
        });
    }

    isBusinessCard(card: ICard): boolean {
        if (
            card &&
            card.hasOwnProperty('ProductCode') &&
            card.hasOwnProperty('ClubCode') &&
            card.ProductCode === '4' &&
            card.ClubCode &&
            card.ClubCode.split(',', 5).includes('301484')
        ) {
            return true;
        }
        return false;
    }

    getMetadata(): Observable<any> {
        return new Observable(observer => {
            const replaceMap = [
                {
                    targetStr: 'leumi-card',
                    newStr: this.appConfigsService.appConfigs.domain
                }
            ];
            this.replaceInArrayOfObj(METADATA, replaceMap);
            observer.next(METADATA);
        });
    }

    getCardProductPages(): Observable<IResponseLower<any>> {
        return this.httpSvc.getLower(`${this.appConfigsService.appConfigs.apiUrl}/orderCard/getCardProductPages`)
        .pipe(
            tap((res: IResponseLower<any>) => {
                if (res.returnCode === 0) {
                    this.cardProductPages = res.result;
                }
            })
        );
    }

    getEligibility(): Observable<any> {
        return new Observable(observer => {
            observer.next(
                METADATA_ELIGIBILITY.find(config => {
                    if (this.card.ProductCode === '4' && config.productCode === this.card.ProductCode) {
                        return config.ClubCode === this.card.ClubCode;
                    }
                    return config.productCode === this.card.ProductCode;
                })
            );
        });
    }

    updateRequest(step?: number, captchaRes?: string, cardLeadIdFromCardPage?: number): Observable<IResponse<IUpdateRequest>> {
        this.setRequest(this.frmSvc.mainFrm.getRawValue());

        const switchKranotIds: boolean =
            step === 1 &&
            !isNullOrUndefined(this.joinRequest['KranotTypeCode']) &&
            (this.joinRequest['KranotTypeCode'] === 1 || this.joinRequest['KranotTypeCode'] === 2) &&
            !isNullOrUndefined(this.joinRequest['IdNumber']) &&
            !isNullOrUndefined(this.joinRequest['IdNumberKranot']);
        if (switchKranotIds) {
            [this.joinRequest['IdNumber'], this.joinRequest['IdNumberKranot']] = [
                this.joinRequest['IdNumberKranot'],
                this.joinRequest['IdNumber']
            ];
        }

        const switchTeacherIds: boolean =
            step === 1 &&
            !isNullOrUndefined(this.joinRequest['TeacherTypeCode']) &&
            (this.joinRequest['TeacherTypeCode'] === 2 || this.joinRequest['TeacherTypeCode'] === 3) &&
            !isNullOrUndefined(this.joinRequest['IdNumber']) &&
            !isNullOrUndefined(this.joinRequest['IdNumberTeacher']);
        if (switchTeacherIds) {
            [this.joinRequest['IdNumber'], this.joinRequest['IdNumberTeacher']] = [
                this.joinRequest['IdNumberTeacher'],
                this.joinRequest['IdNumber']
            ];
        }

        const switchStudentIds = this.switchStudentIdsDetails(step);

        if (this.SttDigitalLtrRegInd === 1 || this.SttBillCode === 1 || this.digitalLettersCode === 1) {
            this.joinRequest['DigitalLettersInd'] = null;
        } else {
            !isNullOrUndefined(this.joinRequest['DigitalLettersInd'])
                ? (this.joinRequest['DigitalLettersInd'] = !this.joinRequest['DigitalLettersInd'])
                : null;
        }

        if (this.transportationTypeCode) {
            this.joinRequest['TransportationTypeCode'] = this.transportationTypeCode;
        }
        return new Observable<IResponse<IUpdateRequest>>(observer => {
            if (this.card) {
                this.joinRequest.ApplicationProduct = parseInt(this.card.ProductCode);
                this.joinRequest.ProductName = !isNullOrUndefined(this.card.Name) ? this.card.Name.toString() : '';
                this.joinRequest.WorkplaceClubCode = !isNullOrUndefined(this.card.ClubCode) ? this.card.ClubCode : '';
                this.joinRequest.InternetCardInfo = !isNullOrUndefined(this.card.Id) ? this.card.Id.toString() : null;
            }

            if (this.salesPromotionAgent) {
                this.joinRequest.SourceCode = this.salesPromotionAgent.SourceCode;
            }

            this.httpSvc.post(`${this.appConfigsService.appConfigs.apiUrl}/orderCard/updateRequest`, {
                    user: this.joinRequest,
                    step: step,
                    recaptchaResponse: captchaRes,
                    cardLeadIdFromCardPage: cardLeadIdFromCardPage,
                    creditDataWasShownOnStep3: step === 3 ? this.creditDataWasShownOnStep3 : false,
                    isFromGetRequest: this.isFromGetRequest
                })
                .subscribe(async (res: IResponse<IUpdateRequest>) => {
                    switch (res.ReturnCode) {
                        case 3:
                            this.gtmService.pushOrderCard("phase " + step, this.card.Name + "_Not_Eligible_Club");
                            break;
                        case 4:
                            if (res.Result)
                                switch (res.Result.ActionCode) {
                                    case ActionCode.CheckEligibilityNotEligible:
                                        this.gtmService.pushOrderCard("phase " + step,
                                            this.getLabelOfGtmByStep(step, this.card.Name));
                                        break;
                                    case ActionCode.NotEligableKranot:
                                    case ActionCode.NotEligableUniversityJerusalem:
                                        this.gtmService.pushOrderCard("phase " + step, this.card.Name + "_Not_Eligible_Club");
                                        break;
                                    case ActionCode.OtherRequestExist:
                                        this.gtmService.pushOrderCard("phase " + step, this.card.Name + "_OpenRequestExists");
                                        break;
                                    case ActionCode.NotEligableHistadrutFamily:
                                    case ActionCode.NotEligableKranotFamily:
                                    case ActionCode.NotEligableKranotChildAge28:
                                    case ActionCode.NotEligableTeacherFamily:
                                        this.gtmService.pushOrderCard("phase " + step, this.card.Name + "_Not_Eligible_ClubFamily");
                                        break;
                                    case ActionCode.OnlyOneCardAtTime:
                                        this.gtmService.pushOrderCard("phase " + step, this.card.Name + "_Not_Eligible_DoubleCard");
                                        break;
                                    case ActionCode.InCustomerService:
                                    case ActionCode.InCustomerService4031:
                                        this.gtmService.pushOrderCard("phase " + step, this.card.Name + "_Technical_White_Error");
                                        break;
                                    case ActionCode.LeumiCardExtraCheck:
                                        this.gtmService.pushOrderCard("phase " + step, this.card.Name + "_CreditCheck");
                                        break;
                                    case ActionCode.NoHivuyDisclaimer:
                                        this.gtmService.pushOrderCard("phase " + step, this.card.Name + "_NoHivuyDisclaimer");
                                        break;
                                    case ActionCode.IDExpired:
                                        this.gtmService.pushOrderCard("phase " + step, this.card.Name + "_ID_Expired");
                                        break;
                                }
                            break;
                        case 9:
                            this.gtmService.pushOrderCard("phase " + step, this.card.Name +"_Technical_Error");
                            break;
                        case 21:
                            this.gtmService.pushOrderCard("phase " + step, this.card.Name + " data error");
                        case 99:
                            this.router.navigate(['/registeredtimeout']);
                            return;
                    }
                    if (res.Result) {
                        this.joinRequest.RequestId = !isNullOrUndefined(res.Result.RequestId)
                            ? res.Result.RequestId.toString()
                            : null;
                        this.AsBsIndication = res.Result.AsBsIndCode;
                        this.CreditLimit = !isNullOrUndefined(res.Result.CreditLimit)
                            ? res.Result.CreditLimit
                            : this.CreditLimit;
                        this.loyaltyCode = !isNullOrUndefined(res.Result.LoyaltyCode)
                            ? res.Result.LoyaltyCode : this.loyaltyCode;
                        this.interest = !isNullOrUndefined(res.Result.Interest)
                            ? res.Result.Interest : this.interest;
                        this.cardTypeHebrew = res.Result.CardTypeHebrew;
                        this.needDocInd =
                            !isNullOrUndefined(res.Result.TzAppendixNeedDocInd) && res.Result.TzAppendixNeedDocInd
                                ? true
                                : false;
                        this.SttDigitalLtrRegInd = !isNullOrUndefined(res.Result.SttDigitalLtrRegInd)
                            ? res.Result.SttDigitalLtrRegInd
                            : null;
                        this.CreditLimitInd =
                            !isNullOrUndefined(res.Result.CreditLimitInd) && res.Result.CreditLimitInd === 1
                                ? true
                                : false;
                        this.CreditLimitTemp = res.Result.CreditLimitTemp;
                        this.IsBasicClub = res.Result.IsBasicClub;
                        this.KranotPopCode = !isNullOrUndefined(res.Result.KranotPopCode)
                            ? res.Result.KranotPopCode
                            : null;
                        this.proposalLogoCodeDesc = !isNullOrUndefined(res.Result.ProposalLogoCodeDesc)
                            ? res.Result.ProposalLogoCodeDesc
                            : this.proposalLogoCodeDesc;
                        this.joinRequest.CreditDataIdenCustomerInd = !isNullOrUndefined(
                            res.Result.CreditDataIdenCustomerInd
                        )
                            ? res.Result.CreditDataIdenCustomerInd
                            : null;
                        this.joinRequest.ApplicationWayCode = !isNullOrUndefined(
                            res.Result.ApplicationWayCode)?
                            res.Result.ApplicationWayCode
                            : null;
                        this.iframeUrlResult = !isNullOrUndefined(
                            res.Result.IframeUrlResult)
                            ? res.Result.IframeUrlResult
                            : null;
                        this.isIframeNeedToBeSigned = !isNullOrUndefined(
                            res.Result.IsIframeNeedToBeSigned)
                            ? res.Result.IsIframeNeedToBeSigned
                            : false;
                        this.creditLimitSplitProposalResult = !isNullOrUndefined(
                            res.Result.CreditLimitSplitDetails)
                            ? res.Result.CreditLimitSplitDetails
                            : null;
                        this.MinimumFix = !isNullOrUndefined(
                            res.Result.MinimumFix)
                            ? res.Result.MinimumFix
                            : null;
                        this.FixPayment = !isNullOrUndefined(
                            res.Result.FixPayment)
                            ? res.Result.FixPayment
                            : null;
                        this.RealInterest = !isNullOrUndefined(
                            res.Result.RealInterest)
                            ? res.Result.RealInterest
                            : null;
                        this.EffectiveInterest = !isNullOrUndefined(
                            res.Result.EffectiveInterest)
                            ? res.Result.EffectiveInterest
                            : null;
                        this.OpenRequestDetails.CreateDate = !isNullOrUndefined(
                            res.Result.CreateDate)
                            ? res.Result.CreateDate
                            : null;
                        this.OpenRequestDetails.CardId = !isNullOrUndefined(
                            res.Result.InternetCardInfo)
                            ? res.Result.InternetCardInfo
                            : null;
                        this.isOpenSkyMaxPopUp = !isNullOrUndefined(
                                res.Result.IsOpenSkyMaxPopUp)
                            ? res.Result.IsOpenSkyMaxPopUp
                            : null;
                        this.transportationTypeCode = !isNullOrUndefined(
                            res.Result.TransportationTypeCode)
                            ? res.Result.TransportationTypeCode
                            : null;
                        if (step == 0 || step == 1) {
                            this.customerExists = !isNullOrUndefined(
                                res.Result.IsExistCustomer)
                                ? res.Result.IsExistCustomer
                                : this.customerExists;
                        }
                        //this.joinRequest.UniInstituteCode = !isNullOrUndefined(
                        //    res.Result.UniInstituteCode)
                        //    ? res.Result.UniInstituteCode
                        //    : null;
                        //this.joinRequest. = !isNullOrUndefined(
                        //        res.Result.InternetCardInfo)
                        //    ? res.Result.InternetCardInfo
                        //    : null;
                        await this.updateOpenRequestDetails();
                        switch (res.ReturnCode) {
                            case 1: // existing customer
                                this.customerType = CustomerType.exist;
                                break;
                        }

                        if (switchKranotIds && (res.ReturnCode === 0 || res.ReturnCode === 1 || res.ReturnCode === 2)) {
                            // switch kranot ids
                            this.frmSvc.setComponentValue('IdNumber', this.joinRequest['IdNumber']);
                            this.frmSvc.setValue('IdNumber', this.joinRequest['IdNumber']);
                            this.frmSvc.setComponentValue('IdNumberKranot', this.joinRequest['IdNumberKranot']);
                            this.frmSvc.setValue('IdNumberKranot', this.joinRequest['IdNumberKranot']);
                        }
                        if (switchTeacherIds && (res.ReturnCode === 0 || res.ReturnCode === 1 || res.ReturnCode === 2)) {
                            // switch teacher ids
                            this.frmSvc.setComponentValue('IdNumber', this.joinRequest['IdNumber']);
                            this.frmSvc.setValue('IdNumber', this.joinRequest['IdNumber']);
                            this.frmSvc.setComponentValue('IdNumberTeacher', this.joinRequest['IdNumberTeacher']);
                            this.frmSvc.setValue('IdNumberTeacher', this.joinRequest['IdNumberTeacher']);
                        }
                        if (switchStudentIds && (res.ReturnCode === 0 || res.ReturnCode === 1 || res.ReturnCode === 2)) {
                            // switch student ids
                            this.frmSvc.setComponentValue('IdNumber', this.joinRequest['IdNumber']);
                            this.frmSvc.setValue('IdNumber', this.joinRequest['IdNumber']);
                            this.frmSvc.setComponentValue('AdditionalIdNumber', this.joinRequest['AdditionalIdNumber']);
                            this.frmSvc.setValue('AdditionalIdNumber', this.joinRequest['AdditionalIdNumber']);
                        }
                    }
                    this.isFromGetRequest = false;
                    observer.next(res);
                });
        });
    }

    async updateOpenRequestDetails(): Promise<void> {
        return new Promise((resolve) => {
            if (isNullOrUndefined(this.OpenRequestDetails.CardId)) return resolve();
            this.restApiService.getCard(this.OpenRequestDetails.CardId).subscribe((card: ICard) => {
                if (isNullOrUndefined(card)) return resolve();
                this.OpenRequestDetails.CardDetails = card;
                this.OpenRequestDetails.CardName = this.OpenRequestDetails.CardDetails.Name;
                if (this.OpenRequestDetails.CardId != this.card.Id.toString()) {
                    this.isOpenRequestCardIdDifferentThenCurrent = true;
                }
                resolve();
            });
        });
    }

    switchStudentIdsDetails(step) {
        const switchStudentIds: boolean =
            step === 1 &&
                !isNullOrUndefined(this.joinRequest['IdNumber']) &&
            !isNullOrUndefined(this.joinRequest['AdditionalIdNumber']);
        if (switchStudentIds) {
            [this.joinRequest['IdNumber'], this.joinRequest['AdditionalIdNumber']] = [
                this.joinRequest['AdditionalIdNumber'],
                this.joinRequest['IdNumber']
            ];
        }
        return switchStudentIds;
    }

    getLabelOfGtmByStep(step: number, cardName: string): string {
        if (step == 2) {
            return this.card.Name + "_Not_Eligible_PreModel";
        } else if (step == 3) {
            return this.card.Name + "_Not_Eligible_Model";
        } else {
            return "";
        }
    }

    getWorkPlaces(): Observable<IResponse<IWorkplace>> {
        return this.httpSvc.get(`${this.appConfigsService.appConfigs.apiUrl}/orderCard/getWorkplaces`);
    }

    checkIfNeedToOfferSyncValues(): Observable<IResponse<boolean>> {
        const cardId = this.card ? this.card.Id : 0;
        return this.httpSvc.post(`${this.appConfigsService.appConfigs.apiUrl}/orderCard/checkIfNeedToOfferSyncValues`,
            { cardId: cardId });
    }

    sendOTP(isSalesRep: boolean): Observable<IResponse<string>> {
        let idNumber = null;

        if (this.joinRequest.IdNumber) {
            idNumber = this.joinRequest.IdNumber;
        } else if (this.joinRequest.MemberIdNumber) {
            idNumber = this.joinRequest.MemberIdNumber;
        }

        const requestId = this.joinRequest.RequestId ? this.joinRequest.RequestId.toString() : null;

        return this.httpSvc.post(`${this.appConfigsService.appConfigs.apiUrl}/orderCard/sendOtp`, {
            RequestId: requestId,
            IdNumber: idNumber,
            IsSalesRep: isSalesRep
        });
    }

    sendOtpRegistered(): Observable<IResponse<any>> {
        return this.httpSvc.post(`${this.appConfigsService.appConfigs.apiUrl}/orderCard/sendOtpRegistered`, null);
    }

    verifyOtpRegistered(smsCode): Observable<IResponse<ICustomerData>> {
        return new Observable<IResponse<ICustomerData>>(observer => {
            this.httpSvc.post<ICustomerData>(
                `${this.appConfigsService.appConfigs.apiUrl}/orderCard/verifyOtpRegistered`,
                {
                    SmsCode: smsCode
                }).subscribe((res: IResponse<ICustomerData>) => {
                    if (res.ReturnCode === 0) {
                        this.syncValues(res.Result);
                        this.otpValid = true;
                        this.needToDisableAddress = true;
                        this.isExistCustomerAlreadySyncedData = true;
                        this.disableFieldsAndUpdateValues();
                    }
                    observer.next(res);
                    },
                err => {
                    observer.error(err);
                });
        });
    }

    getValuesToSyncRegisteredUserWithOtp(): Observable<IResponse<ICustomerData>> {
        return new Observable<IResponse<ICustomerData>>(observer => {
            this.httpSvc.post<ICustomerData>(
                `${this.appConfigsService.appConfigs.apiUrl}/orderCard/syncValuesRegOtp`, null)
                .subscribe((res: IResponse<ICustomerData>) => {
                    if (res.ReturnCode === 0) {
                        this.syncValues(res.Result);
                        this.otpValid = true;
                        this.needToDisableAddress = true;
                        this.disableFieldsAndUpdateValues();
                    }
                    observer.next(res);
                },
                err => {
                    observer.error(err);
                });
        });
    }

    disableFieldsAndUpdateValues() {
        this.frmSvc.formControlsTree['IdNumber'].disable();
        this.frmSvc.formControlsTree['CellularPhoneNumber'].disable();
        const gender = this.frmSvc.formControlsTree['Gender'];
        this.frmSvc.componentTree['Gender'].value = gender.value;
    }

    syncValues(data, skipCustomerData = false) {
        const customerDataSkippedFields = ['FirstName', 'LastName', 'Gender', 'BirthDate']
        for (const key in this.frmSvc.formControlsTree) {
            if (data && data.hasOwnProperty(key) && data[key] != null && (!skipCustomerData || !customerDataSkippedFields.includes(key))) {
                this.frmSvc.formControlsTree[key].setValue(data[key]);
                this.joinRequest[key] = data[key];
            }
        }
        this.isSyncedValues = true;
    }

    syncNotNullOrUndefinedValues(data) {
        const valuesNotEmptyObject = {};
        Object.keys(data).forEach((key) => {
            if (!isNullOrUndefined(data[key]))
                valuesNotEmptyObject[key] = data[key];
        });
        this.syncValues(valuesNotEmptyObject);
    }

    verifyOTP(smsCode): Observable<IResponse<IVerifyOtp>> {
        let idNumber = null;

        if (this.joinRequest.IdNumber) {
            idNumber = this.joinRequest.IdNumber;
        } else if (this.joinRequest.MemberIdNumber) {
            idNumber = this.joinRequest.MemberIdNumber;
        }

        const requestId = this.joinRequest.RequestId;

        return new Observable<IResponse<IVerifyOtp>>(observer => {
            const payload = {
                SmsCode: smsCode,
                RequestId: requestId,
                IdNumber: idNumber,
                FriendJoinCode: this.joinRequest.FriendJoinCode,
                ProductCode: this.card.ProductCode
            };
            this.httpSvc.post(`${this.appConfigsService.appConfigs.apiUrl}/orderCard/verifyOtp`, payload).subscribe(
                (res: IResponse<IVerifyOtp>) => {
                    this.setVerifyOtpData(res, res.Result.ResponseType);
                    if (res.ReturnCode === 0 && this.customerExists && this.joinRequest.ApplicationWayCode != 7) {
                        this.needToDisableAddress = true;
                        this.isExistCustomerAlreadySyncedData = true;
                    }
                    observer.next(res);
                },
                err => {
                    observer.error(err);
                }
            );
        });
    }

    getDeliveryUrl(): Observable<IResponse<string>> {
        return this.httpSvc.post(`${this.appConfigsService.appConfigs.apiUrl}/orderCard/getDeliveryUrl`, null);
    }

    getDeliveryUrlMaxAccount(): Observable<IResponse<{DeliveryUrl?: string; NoladRequestId?: number}>> {
        return this.httpSvc.post(`${this.appConfigsService.appConfigs.apiUrl}/orderCard/getDeliveryUrlMaxAccount`, null);
    }

    uploadFile(): Observable<IResponse<null>> {
        const formData = new FormData();
        formData.append('file', this.file.attachedFile, this.file.attachedFile['name']);
        formData.append('IdNumber', this.joinRequest.IdNumber);
        formData.append('AttachmentCode', this.file.attachmentCode);
        formData.append('DepartmentCode', this.file.departmentCode);
        formData.append('IdDateOfIssue', this.idDateOfIssue);
        formData.append('IdDateOfExpiration', this.idDateOfExpiration);

        return this.httpSvc.multipart(`${this.appConfigsService.appConfigs.apiUrl}/OrderCard/UploadFile`, formData);
    }

    uploadFiles(): Observable<IResponse<null>> {
        const formData = new FormData();
        formData.append('file', this.file.attachedFile, this.file.attachedFile['name']);
        formData.append('backFile', this.backSideFile.attachedFile, this.backSideFile.attachedFile['name']);
        formData.append('IdNumber', this.joinRequest.IdNumber);
        formData.append('AttachmentCode', this.file.attachmentCode);
        formData.append('DepartmentCode', this.file.departmentCode);
        formData.append('IdDateOfExpiration', this.idDateOfExpiration);
        formData.append('IdDateOfIssue', this.idDateOfIssue);

        return this.httpSvc.multipart(`${this.appConfigsService.appConfigs.apiUrl}/OrderCard/UploadFilesForBiometricID`, formData);
    }

    cleanJoinRequest() {
        Object.keys(this.joinRequest).forEach(key => {
            this.joinRequest[key] = null;
        });
    }

    destroyRequest() {
        this.otpValid = false;
        this.cleanJoinRequest();
        if (this.needToCleanToken) {
            this.tokenService.cleanToken();
        }
        this.stepsReady = false;
        this.passedEligibility = false;
        this.frmSvc.destroyForm();
        this.canExit = false;
        this.isNewRequest = true;
        this.card = null;
        this.isTrackerRecoverRequest = false;
        this.foxNeedToPayMembershipInd = null;
        this.isDreamCardSmsSuccess = true;
        this.salesPromotionAgent = null;
        this.proposalLogoCodeDesc = null;
        this.customerExists = false;
        this.isExternalRequest = false;
        this.isExistCustomerAlreadySyncedData = false;
        this.isSyncedValues = false;
        this.setLongSpinnerInfo();
    }

    startNewRequest(): Observable<IResponse<null>> {
        return this.httpSvc.post(`${this.appConfigsService.appConfigs.apiUrl}/orderCard/startNewRequest`, null);
    }

    closeCardRequest(actionCode: string): Observable<IResponse<number>> {
        return this.httpSvc.post(`${this.appConfigsService.appConfigs.apiUrl}/orderCard/closeCardRequest`, { ActionCode: actionCode });
    }

    decryptKranotId(id: string): Observable<IResponse<string>> {
        return this.httpSvc.post(`${this.appConfigsService.appConfigs.apiUrl}/kranot/decryptId`, { Id: id });
    }

    setVerifyOtpData(res: IResponse<any>, responseType: string): void {
        if (responseType === 'InstantIssuingRequest') {
            this.joinRequest.RequestId = !isNullOrUndefined(res.Result.Request.User.RequestId)
                ? res.Result.Request.User.RequestId.toString()
                : null;
        }
        else if (responseType === 'Request') {
            if (this.isTrackerRecoverRequest) {
                this.joinRequest.RequestId = !isNullOrUndefined(res.Result.Request.User.RequestId)
                    ? res.Result.Request.User.RequestId.toString()
                    : null;
            }
            if (!isNullOrUndefined(this.OpenRequestDetails.CardDetails) && this.OpenRequestDetails.CardId !== this.card.Id.toString()) {
                this.card = this.OpenRequestDetails.CardDetails;
                this.setLongSpinnerInfo();
            }
            this.joinRequest.CreditDataAgreeInd = !isNullOrUndefined(res.Result.Request.User.CreditDataAgreeInd)
                ? res.Result.Request.User.CreditDataAgreeInd
                : null;
            this.joinRequest.CreditDataIdenCustomerInd = !isNullOrUndefined(
                res.Result.Request.User.CreditDataIdenCustomerInd
            )
                ? res.Result.Request.User.CreditDataIdenCustomerInd
                : null;
            this.joinRequest.CreditDataAgreeExpDate = !isNullOrUndefined(res.Result.Request.User.CreditDataAgreeExpDate)
                ? res.Result.Request.User.CreditDataAgreeExpDate
                : null;
            this.joinRequest.CreditDataAgreeFirstDate = !isNullOrUndefined(res.Result.Request.User.CreditDataAgreeFirstDate)
                ? res.Result.Request.User.CreditDataAgreeFirstDate
                : null;
            this.syncValues(res.Result[responseType].User);
            this.needDocInd =
                !isNullOrUndefined(res.Result[responseType].User.TzAppendixNeedDocInd) &&
                res.Result[responseType].User.TzAppendixNeedDocInd;
            this.AsBsIndication = !isNullOrUndefined(res.Result[responseType].AsBsIndCode)
                ? parseInt(res.Result[responseType].AsBsIndCode)
                : null;
            this.SttBillCode = !isNullOrUndefined(res.Result[responseType].SttBillCode)
                ? parseInt(res.Result[responseType].SttBillCode)
                : null;
            this.digitalLettersCode = !isNullOrUndefined(res.Result[responseType].DigitalLettersCode)
                ? parseInt(res.Result[responseType].DigitalLettersCode)
                : null;
            this.joinRequest.InternetCardInfo = !isNullOrUndefined(res.Result[responseType].User.InternetCardInfo)
                ? res.Result[responseType].User.InternetCardInfo
                : null;
            this.KranotPopCode = !isNullOrUndefined(res.Result[responseType].User.KranotPopCode)
                ? res.Result[responseType].User.KranotPopCode
                : null;
            this.joinRequest.ApplicationWayCode = !isNullOrUndefined(res.Result[responseType].User.ApplicationWayCode)
                ? res.Result[responseType].User.ApplicationWayCode
                : null;
            this.isNewRequest = false;
            this.MinimumFix = !isNullOrUndefined(
                res.Result[responseType].User.MinimumFix)
                ? res.Result[responseType].User.MinimumFix
                : null;
            this.CreditLimit = !isNullOrUndefined(
                res.Result[responseType].User.CreditLimit)
                ? res.Result[responseType].User.CreditLimit
                : null;
            //this.FixPayment = !isNullOrUndefined(
            //    res.Result[responseType].User.FixedChargeAmount)
            //    ? res.Result[responseType].User.FixedChargeAmount
            //    : null;
            this.RealInterest = !isNullOrUndefined(
                res.Result[responseType].User.RealInterest)
                ? res.Result[responseType].User.RealInterest
                : null;
            this.EffectiveInterest = !isNullOrUndefined(
                res.Result[responseType].User.EffectiveInterest)
                ? res.Result[responseType].User.EffectiveInterest
                : null;
            this.FixedChargeAllowedInd = !isNullOrUndefined(
                res.Result[responseType].User.FixedChargeAllowedInd)
                ? res.Result[responseType].User.FixedChargeAllowedInd
                : null;
            this.isFromGetRequest = true;
            this.isMaxAccountRequest = !isNullOrUndefined(res.Result[responseType].User.SimplePayRequestId);
            if ((!isNullOrUndefined(
                    res.Result[responseType].User.LoyaltySkyMaxPossible) &&
                res.Result[responseType].User.LoyaltySkyMaxPossible === true)) {
                this.isOpenSkyMaxPopUp = isNullOrUndefined(
                    res.Result[responseType].User.IsLoyaltySkyMax);
                this.joinRequest.IsLoyaltySkyMax = res.Result[responseType].User.IsLoyaltySkyMax;
                this.hideBonusSection = (!isNullOrUndefined(
                    res.Result[responseType].User.IsLoyaltySkyMax) &&
                    res.Result[responseType].User.IsLoyaltySkyMax === true);
            }
        } else if (responseType === 'CustomerData') {
            this.syncValues(res.Result[responseType], true);
        }
    }


    getSalesPromotionOrderCardContinueSms(token: string): Observable<IResponse<IContinueExternalRequest>> {
        const httpParams = new HttpParams().set('token', token.toString());
        return this.httpSvc.get(
            `${this.appConfigsService.appConfigs.apiUrl}/orderCard/getSalesPromotionOrderCardContinueSms`,
            httpParams
        );
    }

    sendVerifySMSCode(): Observable<IResponse<ISendOtp>> {
        const idNumber = this.joinRequest.IdNumber;
        const mobilePhoneNumber = this.joinRequest.CellularPhoneNumber;

        return this.httpSvc.post(`${this.appConfigsService.appConfigs.apiUrl}/orderCard/sendVerifySMSCode`, {
            MobilePhoneNumber: mobilePhoneNumber,
            IdNumber: idNumber
        });
    }

    createCardLead(cardLeadDetails: ICardLeadDetails): Observable<IResponse<number>> {
        return this.httpSvc.post<number>(`${this.appConfigsService.appConfigs.apiUrl}/orderCard/createCardLead`,
            {
                FirstName: cardLeadDetails.FirstName,
                LastName: cardLeadDetails.LastName,
                ID: cardLeadDetails.Id,
                Phone: cardLeadDetails.PhoneNumber,
                ApplicationProduct: cardLeadDetails.ApplicationProduct,
                WorkplaceClubCode: cardLeadDetails.WorkplaceClubCode,
                ProductName: cardLeadDetails.ProductName
            });
    }

    getInstitutesByProductId(getInstitutesDetails :IGetInstitutesDetails): Observable<IResponse<IA2CInstitutesByProductIdResult>> {
        return this.httpSvc.post<IA2CInstitutesByProductIdResult>(`${this.appConfigsService.appConfigs.apiUrl}/orderCard/getInstitutesByProductId`,
            {
                ProductId: getInstitutesDetails.ProductId,
            });
    }

    getIsicInstitutes(): Observable<IResponse<IA2CInstitutesByProductIdResult>> {
        return this.httpSvc.get<IA2CInstitutesByProductIdResult>(
            `${this.appConfigsService.appConfigs.apiUrl}/orderCard/getIsicInstitutes`);
    }

    getPopulationsAndTZIndByProductIdAndUniInstituteCode(getPopulationsAndTZIndDetails: IGetPopulationsAndTZIndDetails): Observable<IResponse<IA2CPopulationsAndTZIndResult>> {
        return this.httpSvc.post<IA2CPopulationsAndTZIndResult>(`${this.appConfigsService.appConfigs.apiUrl}/orderCard/getPopulationsAndTZIndByProductIdAndUniInstituteCode`,
            getPopulationsAndTZIndDetails);
    }

    getPictureIndByProductIdInstituteCodeAndPopulationClub(getPictureIndDetails: IGetPictureIndDetails): Observable<IResponse<IA2CPictureNeedIndResult>> {
        return this.httpSvc.post<IA2CPictureNeedIndResult>(`${this.appConfigsService.appConfigs.apiUrl}/orderCard/getPictureIndByProductIdInstituteCodeAndPopulationClub`,
            getPictureIndDetails);
    }

    getIframeUrl() {
        return this.iframeUrlResult;
    }

    resetServerSession(): Observable<IResponse<null>> {
        return this.httpSvc.get(
            `${this.appConfigsService.appConfigs.apiUrl}/orderCard/resetSession`,null
        );
    }

    private isSelectItem(val: SelectItem): val is SelectItem {
        if (val && val.hasOwnProperty('label') && val.hasOwnProperty('value')) {
            return true;
        }
        return false;
    }

    private setRequest(group) {
        for (const key of Object.keys(group)) {
            if (!isNullOrUndefined(group[key])) {
                if (
                    this.isSelectItem(group[key]) &&
                    this.joinRequest.hasOwnProperty(key) &&
                    this.frmSvc.flatComponentTree.hasOwnProperty(key)
                ) {
                    this.joinRequest[key] = (<SelectItem>group[key])[this.frmSvc.flatComponentTree[key].requestTarget];
                } else if (typeof group[key] === 'object' && key !== 'ArrayOfFoxChildDetails') {
                    this.setRequest(group[key]);
                } else if (this.joinRequest.hasOwnProperty(key)) {
                    this.joinRequest[key] = group[key];
                }
            }

            if (this.joinRequest[key] === '') {
                this.joinRequest[key] = null;
            }
        }
    }

    // One time view loading

    private replaceInObj(o, replaceMap) {
        Object.keys(o).forEach(k => {
            if (typeof o[k] === 'object') {
                this.replaceInObj(o[k], replaceMap);
            } else if (typeof o[k] === 'string') {
                replaceMap.forEach(m => {
                    o[k] = o[k].replace(m.targetStr, m.newStr);
                });
            } else {
                return;
            }
        });
    }

    private replaceInArrayOfObj(arr, replaceMap) {
        if (isNullOrUndefined(arr) || isNullOrUndefined(replaceMap)) {
            return;
        }
        arr.filter(i => typeof i === 'object').forEach(o => this.replaceInObj(o, replaceMap));
    }

    private setLongSpinnerInfo() {
        this.spinnerService.title = this.card?.LoaderTitle;
        this.spinnerService.subtitles = this.card?.LoaderSubtitles;
    }
}
