// tslint:disable:max-line-length
import {
    Component,
    OnInit,
    AfterViewInit,
    OnDestroy,
    ViewEncapsulation,
    Output,
    ViewChild,
    EventEmitter
} from '@angular/core';
import { ModalService } from '../../../shared/modules/bs-modal/services/modal.service';
import { IErrorScreenParams } from './login-messages.consts';
import { isNullOrUndefined } from 'core-max-lib';
import { UiHelperService } from '../../../shared/cardholders-core/services/ui-helper.service';
import { BehaviorSubject } from 'rxjs';
import { GtmService } from '../../../shared/cardholders-core/services/gtm.service';
import { LoginType } from '../../../shared/cardholders-core/models/login-result';
import { Subject } from 'rxjs';
import { PlatformLocation, Location } from '@angular/common';
// tslint:disable-next-line:import-blacklist
import { SubscriptionLike } from 'rxjs';
import { ActivatedRoute, Router } from '@angular/router';
import { LoginWithMaxService } from '../../login-with-max/login-with-max.service';
import { FingerPrintService } from '../../../shared/cardholders-core/services/fingerPrint.service';
import { AppConfigsService } from '../../../config/app-configs.service';
import { AuthService } from '../../../shared/cardholders-core/services/auth.service';
import { PrebootService } from 'core-max-lib';
import { DomSanitizer } from '@angular/platform-browser';
import { ILoginPageContents } from '../models/login-page-contents';
import { LoginPageService } from '../login-page/login-page.service';
import { ReturnUrlService } from '../../../shared/cardholders-core/services/return-url.service';
import { TabsetComponent } from 'ngx-bootstrap/tabs';
import { AppleSignInService } from '../../../shared/cardholders-core/services/apple-sign-in.service';
import { Fido2Service } from '../../../shared/cardholders-core/services/fido2.service';
import { isIos } from '../../../shared/services/isIos';
import { uiIsMobile } from '../../../shared/services/isMobile';

declare const AppleID: any;

@Component({
    selector: 'app-login-popup',
    templateUrl: './login-popup.component.html',
    styleUrls: ['./login-popup.component.scss'],
    encapsulation: ViewEncapsulation.None
})
export class LoginPopupComponent implements OnInit, AfterViewInit, OnDestroy {
    isLoginFailed = false;
    isLoginFailedWithTechnicalError = false;
    errorScreenParams: IErrorScreenParams;
    @ViewChild('loginFormTabs')
    loginFormTabs: TabsetComponent;
    hideLoader: BehaviorSubject<boolean>;
    loaderAmount: number = 0;
    step = 1;
    passUserIdToOTPForm: Subject<string> = new Subject<string>();
    @Output()
    otpTabClicked: Subject<void> = new Subject<void>();
    @Output()
    passwordTabClicked: Subject<void> = new Subject<void>();
    locationSubscription: SubscriptionLike;
    loginWithMaxMode: boolean;
    @Output()
    otpCodeSent: Subject<boolean> = new Subject<boolean>();
    isExtraAuthRequired: boolean;
    loadingText = '';
    loginPageMode: boolean;
    isMobile: boolean;
    isOtpFocus: boolean;
    isPasswordFocus: boolean;
    APPLE_SIGN_IN_STORAGE_KEY = 'appleSignIn';
    showAppleSignIn: boolean;
    dontShowEmptyFieldError: boolean;
    showGoogleFingerPrint: boolean;
    activateFp: Subject<boolean> = new Subject<boolean>();
    isFingerPrint: boolean;
    tabs: 'password' | 'otp' = 'otp';
    sendOtpId: string;
    isFromBeyachad: boolean;
    showFido2: boolean = false;
    fido2Username: string;

    constructor(
        private modalSvc: ModalService,
        private uiHelper: UiHelperService,
        private gtmService: GtmService,
        private location: Location,
        private route: ActivatedRoute,
        private loginWithMaxSvc: LoginWithMaxService,
        private fpService: FingerPrintService,
        private router: Router,
        private appConfig: AppConfigsService,
        private authService: AuthService,
        private prebootSvc: PrebootService,
        private sanitizer: DomSanitizer,
        private loginPageSvc: LoginPageService,
        private returnUrlSvr: ReturnUrlService,
        private appleSignInService: AppleSignInService,
        private fido2Service: Fido2Service
    ) {
        const state = this.router.getCurrentNavigation()?.extras?.state;
        if (state && state.id) {
            this.sendOtpId = state.id;
        }
    }

    async ngOnInit(): Promise<any> {
        this.isFromBeyachad = this.route.snapshot.queryParams['SourceGA'] === 'BeyachadSkyMaxLogin';
        this.hideLoader = new BehaviorSubject<boolean>(true);
        this.isExtraAuthRequired = this.authService.isUserAuthenticated();
        this.isMobile = this.uiHelper.IsMobileByScreen(768);
        this.loginPageMode = this.loginPageSvc.isLoginPageMode();
        this.loginWithMaxMode = this.loginWithMaxSvc.isLoginWithMaxMode();
        this.loadingText = this.loginPageMode ? '' : this.loginWithMaxMode ? 'עוד רגע ממריאים...' : 'עוד רגע נכנסים...';
        this.updateLoaderStatus(!this.loginPageMode);
        this.isOtpFocus =
            !isNullOrUndefined(this.route.snapshot.queryParams['Focus']) &&
            this.route.snapshot.queryParams['Focus'] === 'OTP';

        this.isPasswordFocus =
            !isNullOrUndefined(this.route.snapshot.queryParams['Focus']) &&
            this.route.snapshot.queryParams['Focus'] === 'Password';

        if (this.uiHelper.isBrowser && this.uiHelper.IsMobile(768) && !this.loginPageMode) {
            document.querySelector('body').classList.add('overflow');
            document.querySelector('html').classList.add('overflow');
            history.pushState(null, null, location.href);
            this.locationSubscription = this.location.subscribe(() => {
                this.onBackButtonClick();
            });
        }

        if (this.uiHelper.isBrowser && uiIsMobile()) {
            if (
                this.appConfig.appConfigs.FLAG_ISFingerPrintEnabled ||
                this.appConfig.appConfigs.FLAG_ISFingerPrintEnabledWithoutQp
            ) {
                //this.checkFpLogin();
                this.checkFidoLogin();
            }
        }

        this.prebootSvc.transitionComplete.subscribe((isStable: boolean) => {
            if (isStable) {
                if(this.loginPageMode){
                    this.updateLoaderStatus(true);
                }
                this.loadingText = this.loginWithMaxMode ? 'עוד רגע ממריאים...' : 'עוד רגע נכנסים...';
            }
        });
    }

    async ngAfterViewInit(): Promise<any> {
        if (!this.uiHelper.isBrowser) {
            return;
        }



        let loginType = this.loginPageSvc.isAutoLoginFailed()
            ? 'Password'
            : this.isOtpFocus
            ? 'Id'
            : localStorage.getItem('lastLoginTypeSuccess');

        if (!isNullOrUndefined(this.sendOtpId) && this.sendOtpId !== '') {
            loginType = 'Id';
        }

        if(this.isPasswordFocus)
        {
            loginType = 'Password';
        }

        if (!isNullOrUndefined(loginType)) {
            this.switchTab(LoginType[loginType]);
        }
        if (loginType === 'Password') {
            this.gtmService.pushWorkflowEvent({
                category: this.loginPageMode ? 'New website - log in page' : 'New website - log in',
                action: 'Password login',
                label: 'Show',
                subjectId: '4',
                workflowDescription: this.loginPageMode ? 'LoginPassword page' : 'LoginPassword',
                workflowStep: '1',
                workflowTotalStep: '2'
            });
        } else {
            this.gtmService.pushWorkflowEvent({
                category: this.loginPageMode ? 'New website - log in page' : 'New website - log in',
                action: 'OTP - phase 1',
                label: 'Show',
                subjectId: '4',
                workflowDescription: this.loginPageMode ? 'LoginOTP page' : 'LoginOTP',
                workflowStep: '1',
                workflowTotalStep: '3'
            });
        }

        if (localStorage.getItem(this.APPLE_SIGN_IN_STORAGE_KEY)) {
            const data = await Promise.all([
                this.appleSignInService.getChallenge(localStorage.getItem(this.APPLE_SIGN_IN_STORAGE_KEY)).toPromise(),
                window['AppleID'] ? null : this.appleSignInService.loadScript('/assets/js/apple-signin.js')
            ]);
            if (data[0].returnCode === 12) {
                localStorage.removeItem(this.APPLE_SIGN_IN_STORAGE_KEY);
            }
            if (!window['AppleID']) {
                return console.warn('AppleID object not loaded!');
            } else if (data[0].returnCode === 0) {
                AppleID.auth.init({
                    clientId: 'il.co.max.signInService',
                    redirectURI: `${window.location.origin}/api/apple/signIn`,
                    nonce: data[0].result,
                    usePopup: true
                });
                console.log('start signIn nonce - ', data[0].result);
                this.showAppleSignIn = true;
            }
        }
    }

    ngOnDestroy(): void {
        if (this.uiHelper.isBrowser && this.uiHelper.IsMobile(768) && !this.loginPageMode) {
            document.querySelector('body').classList.remove('overflow');
            document.querySelector('html').classList.remove('overflow');
            this.locationSubscription.unsubscribe();
        }
    }

    updateLoaderStatus(loadStatus: boolean): void {
        if(loadStatus && this.loaderAmount > 0){
            this.loaderAmount--
            if(this.loaderAmount > 0){
                return;
            }
        }
        else if(!loadStatus){
            this.loaderAmount++;
        }
        this.hideLoader.next(loadStatus);
    }

    passUserIdToOTPTab(userId: string): void {
        this.passUserIdToOTPForm.next(userId);
    }

    close(): void {
        if (this.popUpInShowState()) {
            this.modalSvc.closeModal();
        }
    }

    popUpInShowState() {
        return document.querySelector('.modal.fade').classList.contains('show');
    }

    onBackButtonClick() {
        // history.forward();
        this.close();
        return false;
    }

    onLoginFailed(params: IErrorScreenParams): void {
        this.errorScreenParams = params;
        this.isLoginFailed = true;
    }
    isFingerPrintResult(res: boolean) {
        this.isFingerPrint = res;
    }

    onLoginFailedWithTechnicalError(): void {
        this.isLoginFailedWithTechnicalError = true;
        if (this.uiHelper.IsMobile(768)) {
            document.querySelector('.login-popup .modal-content button.close span').classList.add('black-color');
        }
    }

    switchTab(tabId: number): void {
        this.loginFormTabs.tabs[tabId].active = true;
    }

    onOtpStepChange(currentStep: number): void {
        this.step = currentStep;
        setTimeout(() => {
            this.otpTabClicked.next();
        });
    }

    onOtpTabClicked(): void {
        this.tabs = 'otp';
        this.otpTabClicked.next();
        this.gtmService.pushWorkflowEvent({
            category: this.loginPageMode ? 'New website - log in page' : 'New website - log in',
            action: 'Password login',
            label: 'Enter with OTP',
            subjectId: '4',
            workflowDescription: this.loginPageMode ? 'LoginOTP page' : 'LoginOTP',
            workflowStep: '1',
            workflowTotalStep: '3'
        });
    }

    onPasswordTabClicked(): void {
        this.tabs = 'password';

        if (this.step === 2) {
            this.otpCodeSent.next(true);
        }
        this.passwordTabClicked.next();
        if (this.step === 1) {
            this.gtmService.pushWorkflowEvent({
                category: this.loginPageMode ? 'New website - log in page' : 'New website - log in',
                action: 'OTP - phase 1',
                label: 'Log in with password',
                subjectId: '4',
                workflowDescription: this.loginPageMode ? 'LoginPassword page' : 'LoginPassword',
                workflowStep: '1',
                workflowTotalStep: '2'
            });
        } else {
            this.gtmService.pushWorkflowEvent({
                category: this.loginPageMode ? 'New website - log in page' : 'New website - log in',
                action: 'OTP - phase 2',
                label: 'Log in with password',
                subjectId: '4',
                workflowDescription: this.loginPageMode ? 'LoginPassword page' : 'LoginPassword',
                workflowStep: '2',
                workflowTotalStep: '2'
            });
        }
    }

    onNeedIdChanged(): void {
        setTimeout(() => {
            this.passwordTabClicked.next();
        });

        this.showAppleSignIn = false;
        this.showGoogleFingerPrint = false;
    }

    private checkFpLogin(): void {
        const fpId = localStorage.getItem('fpId');
        if (fpId) {
            this.fpService.getPublicKey(fpId).subscribe((res) => {
                console.log(res);
                if (res.returnCode === 0) {
                    this.showGoogleFingerPrint = true;

                    setTimeout(() => {
                        this.activateFp.next(true);
                    }, 500);
                }
            });
        }
    }

    private async checkFidoLogin(){
        const fidoUsername = localStorage.getItem("fido2Username");
        if(fidoUsername){
            this.updateLoaderStatus(false);
            await this.fido2Service.signInBiometric(fidoUsername);
            this.fido2Service.assertionSuccess.subscribe(res => {
                if(res === true){
                    this.fido2Username = fidoUsername;
                    this.showFido2 = true;
                }else{
                    this.gtmService.pushDirective({
                        category: this.loginPageMode ? 'New website - log in page' : 'New website - log in',
                        action: 'Password login',
                        label: isIos() ? 'Failed to verify appleSignIn' : 'Failed to verify fingerprint'
                    });
                    this.updateLoaderStatus(true);
                }
            })
        }
    }

    clickBanner(){
        this.gtmService.pushDirective({
            category: "log in page", action: "Download app",
            label: 'click'
        })
        window.location.href = "https://maxapp.onelink.me/2187048928/iejgo2im";
    }

    // private navigateReturnUrlAfterLoginSuccess(): void {
    //    if (this.loginWithMaxMode) {
    //        this.router.navigate(['/loginwithmax/scopes'], { queryParamsHandling: 'merge' });
    //    } else { this.returnUrlSvr.navigateToReturnUrl(); }
    // }
}
