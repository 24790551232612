import { Injectable } from "@angular/core";
import { IepopupComponent } from "../iepopup/iepopup.component";
import { InitializerService, MaxDialogService } from "core-max-lib";
import { CookieService } from "ngx-cookie-service";

@Injectable()
export class NoSupportIePopupLibService {
  constructor(
    private maxDialog: MaxDialogService,
    private cookieService: CookieService,
    private initializer: InitializerService
  ) {}

  init() {
    try
    {
      this.display(()=>
      {
        this.maxDialog.open(IepopupComponent);
      });
    }
    catch(err)
    {
      console.error(err);
    }   
  }

  display(onShow: () => any) {
    if (!this.isIE()) {
      return;
    }

    if (this.cookieService.get("noiesupportpopup") === "true") {
      return;
    } 
    
    const appConfigs = this.initializer.getAppConfigs();
    this.cookieService.set("noiesupportpopup","true",null,null,appConfigs.domain + ".co.il",null,null);

    onShow();
}

  isIE(): boolean {
    var ua = window.navigator.userAgent;
    var msie = ua.indexOf("MSIE "); // IE 10 or older
    var trident = ua.indexOf("Trident/"); //IE 11
    return msie > 0 || trident > 0;
  }
}
