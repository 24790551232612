import { Injectable } from '@angular/core';

@Injectable()
export class AutoFillOtpService {
    constructor() {}

    autoReadSMS(codeLength: number): Promise<string> {
        if ('OTPCredential' in window) {
            const ac = new AbortController();

            setTimeout(() => {
                ac.abort();
            }, 120 * 1000 );

            return navigator.credentials
                .get({
                    otp: { transport: ['sms'] },
                    signal: ac.signal
                } as CredentialRequestOptions)
                .then((otp) => {
                    const code = (otp as any).code;
                    ac.abort();
                    if (code && code.length >= codeLength && /^[0-9]+$/.test(code.substring(0, codeLength))) {
                        return code.substring(0, codeLength);
                    }
                    return null;
                })
                .catch((err) => {
                    ac.abort();
                    return null;
                });
        } else {
            return Promise.resolve(null);
        }
    }

    // autoReadSMSForDebug(codeLength: number): Promise<string> {
    //     if (!window['OTPCredential']) {
    //         window['OTPCredential'] = {};
    //     }
    //     if (!window.navigator.credentials) {
    //         (window.navigator as any).credentials = {};
    //     }

    //     const sleep = new Promise<any>((resolve) => {
    //         setTimeout(() => {
    //             resolve({ code: '123456a7' });
    //         }, 6000);
    //     });

    //     window.navigator.credentials.get = () => sleep;

    //     return this.autoReadSMS(codeLength);
    // }
}
