<!-- <seo-head title="הלוואות כרטיסי אשראי ושירותי סליקה max" searchDescription="מקס מציעים לכם מבחר הלוואות בתנאים נוחים, כרטיסי אשראי שמזכים אתכם בהטבות ייחודיות, ופתרונות סליקה לעסק שלכם"></seo-head> -->
<!--<app-cookies-notification [element]="homepage"></app-cookies-notification>-->
<section class="homepage {{ zoomClass }}" [ngClass]="{'homepage-header': isHeaderTransparent, 'side-nav-open': isNavOpen}" #homepage>
    <app-header [homepageElement]="homepage" (headerSizeChanged)="onHeaderSizeChanged($event)"></app-header>
    <section class="content col-sm-12 homepage-inner" [ngStyle]="getStyleForSection()">
        <router-outlet></router-outlet>
        <app-asteriks [hidden]="removeLayout"></app-asteriks>
        <app-footer *ngIf="!removeLayout"></app-footer>
        <div class="pop-up-back"></div>
        <div class="dark-back-nav-bar"></div>
    </section>
   
</section>

<app-load-style [styleList]="styleLinks"></app-load-style>
