<div class="noiepop-header">
  <button type="button" class="close" data-dismiss="modal" aria-label="Close" (click)="close()"><span aria-hidden="true">×</span></button>
</div>
<div class="noiepop-body">
  <svg class="astro" xmlns="http://www.w3.org/2000/svg" xmlns:xlink="http://www.w3.org/1999/xlink" width="113" height="112" viewBox="0 0 113 112">
    <defs>
      <path id="prefix__a" d="M0 56c0 30.929 25.072 56 56 56s56-25.071 56-56c0-30.928-25.072-56-56-56S0 25.073 0 56z" />
      <path id="prefix__c" d="M.278 12.984s-1.31 22.562 11 26.336c12.311 3.772 16.77-6.46 16.77-6.46S43.518 2.972 22.542.157L.278 12.984z" />
      <path id="prefix__e" d="M.072 9.073l11.925 7.114c.242.162.493.317.76.454 3.106 1.593 6.685.81 7.995-1.747 1.179-2.295.121-5.236-2.352-6.966l.041-.066L5.758.296.072 9.073z" />
      <path id="prefix__g" d="M2.184.434C.928.62.059 1.787.244 3.044.43 4.3 1.598 5.17 2.855 4.985 4.11 4.8 4.978 3.63 4.794 2.375 4.627 1.233 3.644.41 2.523.41c-.112 0-.225.009-.339.025z" />
      <path id="prefix__i" d="M5.023.245C2.38.245.236 2.388.236 5.032V38.53c0 2.645 2.144 4.788 4.787 4.788h35.456c2.644 0 4.788-2.143 4.788-4.788V5.032c0-2.644-2.144-4.787-4.788-4.787H5.023z" />
      <path id="prefix__k" d="M32.127 1.254L3.274 17.914C.239 19.665-.801 23.547.95 26.582c1.752 3.035 5.633 4.076 8.669 2.324l28.853-16.66c3.035-1.752 4.075-5.633 2.323-8.668C39.62 1.542 37.486.403 35.293.403c-1.076 0-2.167.275-3.166.851z" />
      <path id="prefix__m" d="M.465 5.088c-1.018 3.353.874 6.898 4.227 7.918l31.878 9.686c3.354 1.019 6.898-.874 7.916-4.227 1.02-3.353-.872-6.897-4.226-7.917L8.383.861C7.767.674 7.147.586 6.535.586 3.814.586 1.298 2.35.465 5.088z" />
      <path id="prefix__o" d="M.344 13.316S-1.56 67.23 27.78 58.468l14.657-21.211c-2.936-2.873-3.915-23.94-3.915-23.94C30.692 3.42 23.838.121 18.182.121 6.87.122.344 13.316.344 13.316z" />
      <path id="prefix__q" d="M.006 27.69c0 14.932 12.107 27.039 27.039 27.039 14.933 0 27.038-12.107 27.038-27.04C54.083 12.758 41.978.652 27.045.652 12.113.651.006 12.757.006 27.69z" />
      <path id="prefix__s" d="M.202 16.915c0 8.994 7.29 16.287 16.286 16.287 8.996 0 16.288-7.293 16.288-16.287C32.776 7.92 25.484.628 16.488.628 7.493.628.202 7.92.202 16.915z" />
      <path id="prefix__u" d="M.201 16.331c0 1.31.172 2.578.465 3.8C2.38 12.971 8.804 7.644 16.488 7.644c7.685 0 14.11 5.327 15.823 12.487.293-1.222.465-2.49.465-3.8 0-8.996-7.293-16.288-16.288-16.288C7.494.043.201 7.335.201 16.33z" />
      <path id="prefix__w" d="M4.174 9.24L.484.622s.859 5.757 3.69 8.616z" />
      <path id="prefix__y" d="M14.64.35c-.148 0-.3.007-.45.02l-9.249.796C2.106 1.412.008 3.908.252 6.742l.345 3.993c.245 2.834 2.74 4.933 5.575 4.688l9.25-.798c2.833-.245 4.932-2.74 4.688-5.575l-.345-3.993c-.231-2.682-2.48-4.706-5.123-4.706h-.002z" />
      <path id="prefix__A" d="M5.608 1.104l-1.346 1.54L2.92 1.088C2.566.669 2.147.492 1.745.492.956.492.297 1.136.297 1.844c0 .417.129.756.434 1.11l1.573 1.777L.763 6.446C.457 6.8.345 7.138.345 7.54c0 .677.595 1.385 1.432 1.385.417 0 .805-.162 1.175-.596l1.303-1.503L5.56 8.329c.353.419.755.596 1.142.596.868 0 1.448-.708 1.448-1.368 0-.402-.114-.74-.419-1.094L6.204 4.742 7.78 2.97c.305-.354.435-.691.435-1.11 0-.69-.676-1.368-1.45-1.368-.402 0-.788.194-1.157.612z" />
      <path id="prefix__C" d="M3.298.39l-.798.912L1.706.38C1.496.133 1.25.028 1.01.028c-.466 0-.857.38-.857.8 0 .248.077.448.259.657l.93 1.052-.912 1.016c-.181.21-.248.41-.248.648 0 .4.352.82.848.82.248 0 .477-.096.696-.353l.77-.89.773.89c.21.248.448.353.677.353.515 0 .857-.42.857-.81 0-.24-.066-.438-.247-.648L3.65 2.544l.932-1.049c.183-.21.259-.41.259-.658 0-.41-.4-.81-.857-.81-.239 0-.468.114-.686.363z" />
      <path id="prefix__E" d="M4.519.912l-1.06 1.211L2.405.9C2.127.57 1.798.432 1.482.432.862.432.345.937.345 1.495c0 .329.1.594.34.872l1.236 1.395-1.21 1.35c-.24.278-.329.543-.329.86 0 .53.468 1.087 1.126 1.087.328 0 .632-.126.924-.468l1.024-1.18L4.48 6.59c.279.329.596.468.9.468.682 0 1.138-.556 1.138-1.075 0-.316-.088-.581-.33-.86l-1.2-1.353L6.226 2.38c.24-.279.34-.545.34-.873 0-.544-.53-1.075-1.137-1.075-.316 0-.621.152-.91.48z" />
      <path id="prefix__G" d="M3.146.76l-.799.91L1.553.75C1.344.5 1.096.397.857.397c-.466 0-.858.38-.858.8 0 .247.077.448.257.657l.932 1.052-.912 1.015c-.181.21-.248.411-.248.649 0 .4.352.82.849.82.247 0 .477-.096.696-.353l.77-.89.773.89c.209.248.447.352.676.352.515 0 .857-.419.857-.81 0-.238-.065-.438-.247-.648l-.904-1.018.932-1.048c.181-.211.258-.411.258-.66 0-.41-.4-.808-.857-.808-.239 0-.468.113-.685.362z" />
      <path id="prefix__I" d="M4.612.64l-1.06 1.21L2.5.628C2.221.299 1.892.16 1.576.16.956.16.44.666.44 1.222c0 .33.1.595.34.874L2.014 3.49l-1.21 1.35c-.24.277-.328.543-.328.859 0 .532.468 1.088 1.125 1.088.33 0 .633-.127.925-.468L3.55 5.138 4.573 6.32c.279.328.596.468.898.468.684 0 1.138-.556 1.138-1.075 0-.316-.087-.582-.327-.86L5.08 3.5 6.32 2.109c.24-.28.34-.545.34-.874C6.66.69 6.13.16 5.523.16c-.316 0-.62.152-.91.48z" />
    </defs>
    <g fill="none" fill-rule="evenodd" transform="translate(.5)">
      <mask id="prefix__b" fill="#fff">
        <use xlink:href="#prefix__a" />
      </mask>
      <path fill="#F5F5F5" d="M-3.457 115.457L115.457 115.457 115.457 -3.457 -3.457 -3.457z" mask="url(#prefix__b)" />
      <g transform="translate(44.938 67.062)">
        <mask id="prefix__d" fill="#fff">
          <use xlink:href="#prefix__c" />
        </mask>
        <path fill="#67EDD4" d="M-3.22 43.612L36.999 43.612 36.999 -3.299 -3.22 -3.299z" mask="url(#prefix__d)" />
      </g>
      <g stroke="#011652" stroke-linecap="round" stroke-linejoin="round">
        <path stroke-width="2.764" d="M.278 74.516s-1.31 22.561 11 26.334c12.311 3.773 16.77-6.46 16.77-6.46s15.47-29.887-5.506-32.702" transform="translate(44.938 5.53)" />
        <path stroke-width="4.248" d="M22.195 33.611s14.034 7.796 15.593-2.34c1.559-10.135 4.677-11.694 15.593-8.575C64.297 25.814 70.533 11.78 60.398.086" transform="translate(44.938 5.53)" />
      </g>
      <g transform="translate(64.988 59.457)">
        <mask id="prefix__f" fill="#fff">
          <use xlink:href="#prefix__e" />
        </mask>
        <path fill="#FF8164" d="M-3.385 20.923L24.69 20.923 24.69 -3.161 -3.385 -3.161z" mask="url(#prefix__f)" />
      </g>
      <path stroke="#011652" stroke-linecap="round" stroke-linejoin="bevel" stroke-width="2.832" d="M70.746 59.753l-5.687 8.777 11.926 7.113c.242.163.493.318.76.455 3.105 1.592 6.685.81 7.994-1.747 1.18-2.295.122-5.237-2.352-6.966l.042-.066-12.683-7.566z" />
      <g transform="translate(70.519 64.296)">
        <mask id="prefix__h" fill="#fff">
          <use xlink:href="#prefix__g" />
        </mask>
        <path fill="#FFF" d="M-3.237 8.466L8.275 8.466 8.275 -3.047 -3.237 -3.047z" mask="url(#prefix__h)" />
      </g>
      <g transform="translate(26.272 31.802)">
        <mask id="prefix__j" fill="#fff">
          <use xlink:href="#prefix__i" />
        </mask>
        <path fill="#FFDA07" d="M-3.22 46.775L48.724 46.775 48.724 -3.212 -3.22 -3.212z" mask="url(#prefix__j)" />
      </g>
      <path stroke="#011652" stroke-linecap="round" stroke-linejoin="round" stroke-width="2.764" d="M66.75 75.12H31.295c-2.643 0-4.787-2.143-4.787-4.788V36.834c0-2.644 2.144-4.787 4.787-4.787H66.75c2.645 0 4.789 2.143 4.789 4.787v33.498c0 2.645-2.144 4.788-4.789 4.788z" />
      <g transform="translate(39.407 49.778)">
        <mask id="prefix__l" fill="#fff">
          <use xlink:href="#prefix__k" />
        </mask>
        <path fill="#67EDD4" d="M-3.357 33.214L45.104 33.214 45.104 -3.054 -3.357 -3.054z" mask="url(#prefix__l)" />
      </g>
      <path stroke="#011652" stroke-linecap="round" stroke-linejoin="round" stroke-width="2.764" d="M77.88 62.024l-28.853 16.66c-3.035 1.751-6.916.71-8.668-2.324-1.753-3.035-.714-6.917 2.322-8.67l28.853-16.658c3.035-1.753 6.917-.712 8.669 2.324 1.753 3.035.713 6.916-2.323 8.668z" />
      <g transform="translate(8.296 53.926)">
        <mask id="prefix__n" fill="#fff">
          <use xlink:href="#prefix__m" />
        </mask>
        <path fill="#67EDD4" d="M-3.267 26.424L48.219 26.424 48.219 -2.871 -3.267 -2.871z" mask="url(#prefix__n)" />
      </g>
      <path stroke="#011652" stroke-linecap="round" stroke-linejoin="round" stroke-width="2.764" d="M16.679 54.787l31.877 9.687c3.354 1.02 5.246 4.564 4.227 7.917-1.02 3.353-4.563 5.246-7.917 4.227L12.988 66.93c-3.353-1.019-5.245-4.563-4.226-7.917 1.018-3.353 4.562-5.246 7.917-4.227z" />
      <g transform="translate(23.506 45.63)">
        <mask id="prefix__p" fill="#fff">
          <use xlink:href="#prefix__o" />
        </mask>
        <path fill="#67EDD4" d="M-3.137 62.888L45.894 62.888 45.894 -3.335 -3.137 -3.335z" mask="url(#prefix__p)" />
      </g>
      <path stroke="#011652" stroke-linecap="round" stroke-linejoin="round" stroke-width="2.764" d="M65.944 82.887c-2.937-2.873-3.916-23.941-3.916-23.941-23.494-29.686-38.178 0-38.178 0s-1.904 53.914 27.437 45.152" />
      <g transform="translate(14.519 14.519)">
        <mask id="prefix__r" fill="#fff">
          <use xlink:href="#prefix__q" />
        </mask>
        <path fill="#67EDD4" d="M-3.45 58.185L57.54 58.185 57.54 -2.806 -3.45 -2.806z" mask="url(#prefix__r)" />
      </g>
      <path stroke="#011652" stroke-linecap="round" stroke-linejoin="round" stroke-width="2.764" d="M68.602 42.208c0 14.933-12.106 27.038-27.039 27.038-14.932 0-27.038-12.105-27.038-27.038S26.63 15.17 41.563 15.17c14.933 0 27.039 12.105 27.039 27.038z" />
      <g transform="translate(18.667 26.272)">
        <mask id="prefix__t" fill="#fff">
          <use xlink:href="#prefix__s" />
        </mask>
        <path fill="#FFF" d="M-3.255 36.659L36.233 36.659 36.233 -2.829 -3.255 -2.829z" mask="url(#prefix__t)" />
      </g>
      <path stroke="#011652" stroke-linecap="round" stroke-linejoin="round" stroke-width="2.832" d="M51.442 43.187c0 8.994-7.291 16.287-16.288 16.287-8.994 0-16.286-7.293-16.286-16.287 0-8.996 7.292-16.288 16.286-16.288 8.997 0 16.288 7.292 16.288 16.288z" />
      <g transform="translate(18.667 26.963)">
        <mask id="prefix__v" fill="#fff">
          <use xlink:href="#prefix__u" />
        </mask>
        <path fill="#011652" d="M-3.256 23.588L36.233 23.588 36.233 -3.413 -3.256 -3.413z" mask="url(#prefix__v)" />
      </g>
      <path stroke="#011652" stroke-linecap="round" stroke-linejoin="round" stroke-width="2.832" d="M37.734 55.667c4.12-.981 7.45-4.015 8.84-7.969" />
      <g transform="translate(47.012 94.025)">
        <mask id="prefix__x" fill="#fff">
          <use xlink:href="#prefix__w" />
        </mask>
        <path fill="#67EDD4" d="M-2.972 12.696L7.631 12.696 7.631 -2.833 -2.972 -2.833z" mask="url(#prefix__x)" />
      </g>
      <path stroke="#011652" stroke-linecap="round" stroke-linejoin="round" stroke-width="2.764" d="M47.497 94.648s.858 5.756 3.69 8.616" />
      <g transform="translate(34.568 73.284)">
        <mask id="prefix__z" fill="#fff">
          <use xlink:href="#prefix__y" />
        </mask>
        <path fill="#FFF" d="M-3.224 18.899L23.586 18.899 23.586 -3.107 -3.224 -3.107z" mask="url(#prefix__z)" />
      </g>
      <g transform="translate(102.321 73.284)">
        <mask id="prefix__B" fill="#fff">
          <use xlink:href="#prefix__A" />
        </mask>
        <path fill="#021652" d="M-3.16 12.382L11.671 12.382 11.671 -2.965 -3.16 -2.965z" mask="url(#prefix__B)" />
      </g>
      <g transform="translate(85.037 91.26)">
        <mask id="prefix__D" fill="#fff">
          <use xlink:href="#prefix__C" />
        </mask>
        <path fill="#021652" d="M-3.305 8.477L8.297 8.477 8.297 -3.429 -3.305 -3.429z" mask="url(#prefix__D)" />
      </g>
      <g transform="translate(95.407 96.79)">
        <mask id="prefix__F" fill="#fff">
          <use xlink:href="#prefix__E" />
        </mask>
        <path fill="#021652" d="M-3.112 10.516L10.023 10.516 10.023 -3.025 -3.112 -3.025z" mask="url(#prefix__F)" />
      </g>
      <g transform="translate(.691 14.519)">
        <mask id="prefix__H" fill="#fff">
          <use xlink:href="#prefix__G" />
        </mask>
        <path fill="#021652" d="M-3.457 8.846L8.145 8.846 8.145 -3.061 -3.457 -3.061z" mask="url(#prefix__H)" />
      </g>
      <g transform="translate(8.296 4.148)">
        <mask id="prefix__J" fill="#fff">
          <use xlink:href="#prefix__I" />
        </mask>
        <path fill="#021652" d="M-3.018 10.244L10.117 10.244 10.117 -3.296 -3.018 -3.296z" mask="url(#prefix__J)" />
      </g>
    </g>
  </svg>
  <span class="title">
    האתר שלנו לא תומך בדפדפן Internet Explorer
  </span>
  <p>
    כדי לבצע פעולות ולצפות במידע צריך לעבור לדפדפן אחר
    <a href="https://www.google.com/chrome/" target="_blank">להורדת כרום</a>
  </p>
  <button type="button" class="general-button agree" data-dismiss="modal" aria-label="Close" (click)="close()"><span>הבנתי, תודה</span></button>
</div>
