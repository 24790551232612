import { ɵNullViewportScroller } from '@angular/common';
import { Injectable } from '@angular/core';
import { NavigationExtras, Router } from '@angular/router';
import { ITokenResult } from '../../../shared/modules/max-scav/scav/interfaces/token-result.interface';
import { Observable, of, Subject, Subscription } from 'rxjs';
import { tap } from 'rxjs/operators';
import { AppConfigsService } from '../../../config/app-configs.service';
import { DynamicPopupComponent } from '../../../shared/cardholders-core/components/dynamic-popup/dynamic-popup.component';
import { GtmService } from '../../../shared/cardholders-core/services/gtm.service';
import { HttpService, IResponseLower } from 'core-max-lib';
import { IModalConfig } from '../../../shared/modules/bs-modal/interfaces/modal-config.interface';
import { ModalService } from '../../../shared/modules/bs-modal/services/modal.service';
import { ICheckLockVerifyOtpRequest } from '../models/check-lock-verify-otp.interface';
import { IGetRequestSendOtpRequest, IGetRequestSendOtpResponse } from '../models/get-request-send-otp.interface';
import { ICustomerIdentificationResult } from '../models/customer-identification-result.interface';
import { IStep } from '../models/step.enum';
import { IUserSettingsStepResult } from '../models/user-settings-step-result.interface';
import { IVerifyGuidReq } from '../interfaces/verify-guid.interface';
import { customerIdentificationRes } from '../data/customer-identification-details';
import { ScavState } from '../enums/scav-state.enum';
import { IGetUrlResult } from '../models/get-url-result.model';

const iioLeavingPopup: IModalConfig = {
    content: DynamicPopupComponent,
    type: 'iioLeavingPopup',
    options: {
        initialState: {
            data: {
                showCloseButton: false,
                closeOnAction: true,
                img: '',
                title: 'כבר עוזבים?',
                text: 'היציאה תעצור את התהליך',
                btnText: 'להמשיך',
                linkText: 'להתראות ותודה רבה'
            }
        } as Object,
        ignoreBackdropClick: true
    }
};

@Injectable()
export class CustomerIdentificationService {
    isShowLeavingPopup = false;
    subs: Subscription[] = [];
    showHeader = true;
    customerIdentificationResult: ICustomerIdentificationResult = customerIdentificationRes;
    getRequestSendOtpRequest: IGetRequestSendOtpRequest = { idNumber: null, requestId: null, isMobile: null, captchaRes: null };
    checkLockVerifyOtpRequest: ICheckLockVerifyOtpRequest = { otpCode: null };

    sentToNumber: string;
    step: IStep;
    codeMode = false;
    userName: string;
    birthDate: Date;
    requestId: string;
    /** To differ between the two modes of password page. */
    userExists: boolean;
    dontWantDigitalCard: boolean;
    isOtpRequired = true;
    isAfterJoinForm = false;

    state: ScavState;
    url: string;
    callbackUrl: string;
    sessionToken: string;
    returnCode : number = 0;

    stepsMap = [
        { step: 1, path: 'start' },
        { step: 2, path: 'steps-info' },
        { step: 3, path: 'otp' }, //
        { step: 4, path: 'ocr' }, //
        { step: 5, path: 'liveness' }, //
        { step: 6, path: 'approval' },
        { step: 7, path: 'signing' },
        { step: 8, path: 'max-user' },
        { step: 9, path: 'password' },
        { step: 10, path: 'four-digits' },
        { step: 50, path: 'try-again' }, //
        { step: 100, path: 'finish' }//
    ];
    canExit = false;
    stopInstantIssuingOrderPopupNavigateAwaySelection: Subject<boolean> = new Subject<boolean>();
    navigationExtras: NavigationExtras = { skipLocationChange: false };
    showOcrLayout = new Subject();
    isAgentHandlingRequest = false;
    isAgreedToInsurance?: boolean;
    isAgreedToBank?: boolean;
    cardName = '';
    identMethodRequiredCode: number;
    livenessTryAgain = false;
    isNoNeedOtp = false;

    constructor(public appConfigsService: AppConfigsService,
        private httpSvc: HttpService,
        private router: Router,
        private modalService: ModalService,
        private gtmService: GtmService) {
            this.callbackUrl = `${this.appConfigsService.appConfigs.baseUiRoot}/scav2/end`;
    }

    verifyGuid(verifyGuidReq: IVerifyGuidReq): Observable<IResponseLower<any>> {
        return this.httpSvc.postLower<any>(`${this.appConfigsService.appConfigs.apiUrl}/customerIdentification/verifyGuid`, verifyGuidReq);
    }

    getCustomerIdentificationDetails(): Observable<IResponseLower<ICustomerIdentificationResult>> {
        const res: IResponseLower<ICustomerIdentificationResult> = {
            result: customerIdentificationRes,
            returnCode: 0,
            rcDesc: '',
            correlationID: ''
        };

        return of(res);
    }

    redirectTo(uri: string, extra: NavigationExtras): void {
        this.router.navigate([uri], extra);
    }

    goToMessage(tryAgainState: string, showLink: boolean): void {
        this.redirectTo('/customer-identification/try-again',
        { state: { showLink: showLink, type: tryAgainState }, queryParams: this.navigationExtras.queryParams });
    }

    goToStep(): void {
        switch (this.step) {
            case IStep.Identification:
                if (this.identMethodRequiredCode === 4 || this.identMethodRequiredCode === 5) {
                    this.router.navigate(['/customer-identification/ocr'], this.navigationExtras);
                } else {
                    // error
                }
                break;
            default:
        }
    }

    checkOcrDetails(): Observable<IResponseLower<any>> {
        return this.httpSvc.postLower<any>(
            `${this.appConfigsService.appConfigs.apiUrl}/customerIdentification/checkOcrDetails`,
            null
        );
    }

    checkOcrFailDetails(): Observable<IResponseLower<any>> {
        return this.httpSvc.postLower<any>(
            `${this.appConfigsService.appConfigs.apiUrl}/customerIdentification/checkOcrFailDetails`,
            null
        );
    }

    initOcr(): Observable<IResponseLower<ITokenResult>> {
        return this.httpSvc.postLower<any>(
            `${this.appConfigsService.appConfigs.apiUrl}/customerIdentification/initOcr`,
            null
        );
    }

    checkLivenessDetails(): Observable<IResponseLower<any>> {
        return this.httpSvc.postLower<any>(
            `${this.appConfigsService.appConfigs.apiUrl}/customerIdentification/checkLivenessDetails`,
            null
        );
    }

    showLeavingPopup(): void {
        if (!this.isShowLeavingPopup) {
            this.isShowLeavingPopup = true;
            this.subs.push(this.modalService.openModal(iioLeavingPopup).subscribe(res => {
                this.isShowLeavingPopup = false;
                if (res === 1) {
                    this.stopInstantIssuingOrderPopupNavigateAwaySelection.next(false);
                } else if (res === 2) {
                    this.canExit = true;
                    this.stopInstantIssuingOrderPopupNavigateAwaySelection.next(true);
                }
            }));
        }
    }

    clearSubs(): void {
        this.subs.forEach(s => s.unsubscribe());
    }

    getURL(workflowId: number | string): Observable<IResponseLower<IGetUrlResult>> {
        this.state = ScavState.LoadURL;
        const request = { workflowId : workflowId, callbackURL: this.callbackUrl};
        return this.httpSvc.
        postLower(`${this.appConfigsService.appConfigs.apiUrl}/customerIdentification/geturl`, request)
            .pipe(tap((res: IResponseLower<any>) => {
            if (res.returnCode === 0 && res.result) {
                this.url = res.result.link;
                this.state = ScavState.Active;
            }
            else {
                this.state = ScavState.Failed;
            }
        }));
    }

    getResults(): Observable<IResponseLower<any>> {
    this.state = ScavState.LoadResults;
    const request = { sessionToken : this.sessionToken};

    return this.httpSvc.postLower(`${this.appConfigsService.appConfigs.apiUrl}/customerIdentification/getresults`, request).pipe(
        tap((res: IResponseLower<any>) => {
                if (res.returnCode === 0) {
                        this.state = ScavState.Results;
                }
                else {
                    this.state = ScavState.Failed;
                }
        }));
    }
}
