import { NgModule, ModuleWithProviders } from '@angular/core';
import { CommonModule } from '@angular/common';
import { IepopupComponent } from './iepopup/iepopup.component';
import { NoSupportIePopupLibService } from './services/no-support-ie-popup-lib.service';
import { MaxDialogModule } from 'core-max-lib';
import { CookieService } from 'ngx-cookie-service';

@NgModule({
  declarations: [IepopupComponent],
  imports: [CommonModule, MaxDialogModule],
  providers: [NoSupportIePopupLibService, CookieService],
  entryComponents: [IepopupComponent],
  exports: []
})
export class NoSupportIePopupLibModule {}
