<section *ngIf="approvedCardsCount > 0">
    <div class="container">
        <h2 id="mymaxcardsheader">
            כרטיסים נטענים <span *ngIf="approvedCardsCount">({{ approvedCardsCount }})</span>
            <a
                class="tooltip-icon header-tool-tip"
                appTooltip
                [tooltipText]="tooltipText"
                [tooltipHtmlParent]="'mymaxcardsheader'"
            ></a>
        </h2>
        <div
            class="card-details"
            [ngClass]="{
                'one-card': this.approvedCardsCount === 1,
                'two-cards': this.approvedCardsCount === 2,
                'three-cards': this.approvedCardsCount === 3,
                'only-four-cards': this.approvedCardsCount === 4,
                'four-cards': this.approvedCardsCount > 4
            }"
        >
            <div class="card-box-wrapper" *appLoader="myCardsIsLoaded; template: myCardsloaderTemplateEl">
                <div class="only-card-wrapper">
                    <div
                        class="card card-box card-box-url"
                        *ngFor="let card of this.userCardsToShow; index as j"
                        appGtm
                        [data]="{
                            category: 'New website - registered',
                            action: 'Click on card',
                            label:
                                card.OpenToBuy > 0 && card.OpenToBuy < card.CreditLimit
                                    ? '0-100% CreditLimit - Click'
                                    : card.OpenToBuy === 0
                                    ? '0% Credit limit -Click'
                                    : card.OpenToBuy < 0
                                    ? 'Less 0% -Click'
                                    : 'Over 100% -Click'
                        }"
                        (click)="this.redirectToDebitPage(card.Index, card.CycleSummary[0].Date, $event)"
                    >

                        <div class="card-detail-wrapper">
                            <img src="{{ card?.CardImage }}" alt="" class="card-pic" />
                            <div
                            *ngIf="card?.IsActiveDigitalCard"
                            class="digital-card-ind"
                            [attr.id]="'digital-card_' + card.Index"
                        >
                            <img src="/assets/images/homepage/contactless.svg" alt="" class="digital-ind-pic" />
                            <span>כרטיס דיגיטלי</span>
                            <a
                                class="tooltip-icon digital-card-tooltip"
                                appTooltip
                                [tooltipText]="digitalCardTooltipText"
                                [tooltipHtmlParent]="'digital-card_' + card.Index"
                            ></a>
                             </div>
                            <span class="name">{{ card.MyMaxHebrewName }}</span>
                            <h4>{{ card.Last4Digits + ' ' + card.CardName }}</h4>
                            <span class="valid" >כרטיס נטען</span>

                        </div>
                        <div class="obligation">
                            <div
                                class="sum"
                            >
                                <span class="currency-wrapper price">
                                    <span class="currency-block">
                                        {{card.OpenToBuy | maxCurrency: 'ILS'}}
                                    </span>
                                </span>
                                <span class="date" *ngIf="card.CycleSummary[0]"
                                    >יתרת הכרטיס</span
                                >
                            </div>
                        </div>
                        <a
                        id="clickableLink"
                        [routerLink]="[card.OpenToBuy < 0? '/my-max/personal/charge-card': '/my-max/personal/child-card-details']"
                        [queryParams]="{ mymaxCardIndex: j }"
                        class="link"
                        >{{card.OpenToBuy < 0? 'לטעינת הכרטיס': 'לפעולות וטעינה'}}</a
                    >
                    </div>
                </div>
                <div>
                    <div
                        [ngClass]="{ 'show-all-cards': true, 'show-less-card': this.isShowAllCards }"
                        (click)="toggleCards()"
                        *ngIf="this.isShowCardsText"
                        appGtm
                        [data]="{
                            category: 'New website - registered',
                            action: 'My cards',
                            label: this.isShowAllCards ? 'Expend' : 'Shrink'
                        }"
                        listen="onclick"
                    >
                        <span *ngIf="this.isShowAllCards">תראו לי פחות כרטיסים</span>
                        <span *ngIf="!this.isShowAllCards">תראו לי את כל הכרטיסים</span>
                    </div>
                </div>
            </div>
        </div>
    </div>
</section>

<ng-template #myCardsloaderTemplateEl>
    <div class="card-box-wrapper">
        <div
            class="expected-obligation expected-obligation-template card-box"
            [ngClass]="{ 'app-loader-blink': myCardsShowLoaderBlink }"
        ></div>
        <div [ngClass]="{ 'only-card-wrapper': !myCardsShowLoaderBlink }">
            <div class="card card-box card-template" [ngClass]="{ 'app-loader-blink': myCardsShowLoaderBlink }">
                <div class="card-detail-wrapper"></div>
            </div>
            <div class="card card-box card-template" [ngClass]="{ 'app-loader-blink': myCardsShowLoaderBlink }">
                <div class="card-detail-wrapper"></div>
            </div>
        </div>
    </div>
</ng-template>

<ng-template #recommendationLoaderTemplateEl>
    <app-recommendation-box
        class="recommendation-box recommendation-box-template app-loader-blink"
    ></app-recommendation-box>
</ng-template>
