import { Component, OnInit, ViewEncapsulation, ElementRef, AfterContentChecked } from "@angular/core";
import { MaxDialogRef } from "core-max-lib";

@Component({
  selector: "app-iepopup",
  templateUrl: "./iepopup.component.html",
  styleUrls: ["./iepopup.component.scss"]
})
export class IepopupComponent implements AfterContentChecked {
  
  constructor(public dialogRef: MaxDialogRef, private element: ElementRef) {
  }

  ngAfterContentChecked(): void {
    this.element.nativeElement.parentElement.style.overflow = 'visible'
  }

  close(): void {
    this.dialogRef.close();
  }
}
